.design8,
.visual2 {
  margin: 0;
}

.visual2 {
  white-space: pre-wrap;
  font-size: 13.125rem;
}

.visual-design-h1-container {
  align-self: stretch;
  position: relative;
  line-height: 11.25rem;
}

.uxui-319,
.wewe-can-help6 {
  position: relative;
  line-height: 3.13rem;
  font-family: var(--font-suisse-intl-condensed);
}

.wewe-can-help6 {
  align-self: stretch;
}

.uxui-319 {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-underline-offset: 0.4rem;

  &>a {
    color: var(--white-100);

    &:hover {
      color: var(--primary-color);
    }
  }
}

.ux18 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-320 {
  position: relative;
  text-decoration: underline;
  line-height: 3.13rem;
}

.ux19 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-321 {
  position: relative;
  text-decoration: underline;
  line-height: 3.13rem;
}

.ux20 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-322 {
  position: relative;
  text-decoration: underline;
  line-height: 3.13rem;
}

.ux21 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-323 {
  position: relative;
  text-decoration: underline;
  line-height: 3.13rem;
}

.ux22 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-324 {
  position: relative;
  text-decoration: underline;
  line-height: 3.13rem;
}

.frame-3115-21,
.ux23 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.frame-3115-21 {
  width: 77rem;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--font-size-13xl);
}

.visual-design-anchors {
  font-size: var(--font-size-21xl);
}

.visual-desig-perex,
.visual-desigb-perex,
.visual-design-anchors {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.visual-desig-perex {
  gap: 5rem;
}

.visual-desigb-perex {
  padding: var(--padding-base) var(--padding-5xl) 0 var(--padding-base);
}

.group-inner {
  position: absolute;
  top: calc(50% - 300px);
  left: calc(50% - 640px);
  width: 80rem;
  height: 37.5rem;
}

.web-design-built1 {
  line-height: 4.06rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4.063rem;
  margin: 0;
  text-wrap: nowrap;
}

.frame-parent27 {
  width: 80rem;
  position: relative;
  height: 37.5rem;
}

.in-todays-digital4 {
  align-self: stretch;
  flex: 1;
  position: relative;
}

.website-design,
.website-design-body {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.website-design-body {
  flex-direction: row;
  padding: 0 var(--padding-199xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  font-family: var(--font-suisse-intl);
  padding-top: 5rem;
}

.website-design {
  flex-direction: column;
  /* padding-top: 10rem; */
  padding-top: 2.7rem;
  padding-bottom: 6.15rem;
}

.app-design-text,
.group-child1 {
  position: absolute;
  left: 0;
  width: 80rem;
}

.group-child1 {
  top: 0;
  height: 37.5rem;
}

.app-design-text {
  top: 16.75rem;
  line-height: 4.06rem;
  display: inline-block;
}

.frame-parent28 {
  width: 80rem;
  position: relative;
  height: 37.5rem;
}

.mobile-applications-have7 {
  width: 52.75rem;
  position: relative;
  white-space: pre-wrap;
  display: inline-block;
  height: 11.63rem;
  flex-shrink: 0;
}

.app-design,
.frame-3099-5 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-3099-5 {
  flex-direction: row;
  padding: 0 var(--padding-199xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  font-family: var(--font-suisse-intl);
}

.app-design {
  flex-direction: column;
  gap: var(--gap-13xl);
}

.group-child2,
.ui-ux-text {
  position: absolute;
  left: 0;
  width: 80rem;
}

.group-child2 {
  top: 0;
  height: 37.5rem;
}

.ui-ux-text {
  top: 16.75rem;
  line-height: 4.06rem;
  display: inline-block;
}

.frame-parent29 {
  width: 80rem;
  position: relative;
  height: 37.5rem;
}

.user-interface-ui4 {
  flex: 1;
  position: relative;
}

.ui-ux-body,
.ui-ux-design {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.ui-ux-body {
  flex-direction: row;
  padding: 0 var(--padding-199xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  font-family: var(--font-suisse-intl);
}

.ui-ux-design {
  flex-direction: column;
  gap: var(--gap-13xl);
}

.you-ask-wewe5 {
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
}

.designwewestudiosk-16-43 {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: underline;
}

.mobil-mail-black-33 {
  width: 29.5rem;
  position: relative;
  height: 4.06rem;
}

.frame-2988-6-22 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-base);
}

.take-a-look-16-23 {
  /* width: 14.56rem; */
  position: relative;
  display: inline-block;
  font-weight: 700;
}

.group-1003-15-2-icon3,
.group-1004-15-2-icon3,
.group-16-2-icon3 {
  width: 4.13rem;
  position: relative;
  height: 4.13rem;
}

.frame-2982-15-23,
.frame-3046-15-23 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-3046-15-23 {
  gap: 0 1rem;
}

.frame-2982-15-23 {
  border-radius: 46.26px;
}

.desktop-service-info3,
.frame-3244-22 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.frame-3244-22 {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-base);
}

.desktop-service-info3 {
  width: 80rem;
  background-color: var(--black-100);
  flex-direction: row;
  padding: var(--padding-base) var(--padding-109xl);
  box-sizing: border-box;
  gap: var(--gap-45xl);
  text-align: left;
  font-size: var(--font-size-31xl);
  padding-top: var(--gap-109xl);
  padding-bottom: var(--gap-109xl);
  font-family: var(--font-suisse-intl-condensed-700);
}

.group-child3,
.logo-design-built1 {
  position: absolute;
  left: 0;
  width: 80rem;
}

.group-child3 {
  top: 0;
  height: 37.5rem;
}

.logo-design-built1 {
  top: 16.69rem;
  line-height: 4.06rem;
  display: inline-block;
}

.frame-parent30 {
  width: 80rem;
  position: relative;
  height: 37.5rem;
}

.a-meticulous-approach,
.at-wewe-we,
.your-logo-is6 {
  margin: 0;
}

.your-logo-is-container2 {
  align-self: stretch;
  flex: 1;
  position: relative;
}

.frame-3102-7,
.logo-design1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-3102-7 {
  flex-direction: row;
  padding: 0 var(--padding-199xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  font-family: var(--font-suisse-intl);
}

.logo-design1 {
  flex-direction: column;
  gap: var(--gap-13xl);
}

.group-child4,
.logo-design-built2 {
  position: absolute;
  left: 0;
  width: 80rem;
  mix-blend-mode: normal;
}

.group-child4 {
  top: 0;
  height: 37.5rem;
}

.logo-design-built2 {
  top: 16.69rem;
  line-height: 4.06rem;
  display: inline-block;
}

.frame-parent31 {
  width: 80rem;
  position: relative;
  height: 37.5rem;
}

.typography-and-fonts2 {
  flex: 1;
  position: relative;
}

.frame-3107-3,
.typography {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-3107-3 {
  flex-direction: row;
  padding: 0 var(--padding-199xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  font-family: var(--font-suisse-intl);
}

.typography {
  flex-direction: column;
  gap: var(--gap-13xl);
}

.group-child5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 80rem;
  height: 37.5rem;
}

.purpose-built-fonts-2 {
  position: absolute;
  top: 16.69rem;
  left: 0;
  line-height: 4.06rem;
  display: inline-block;
  width: 80rem;
}

.frame-parent32 {
  width: 80rem;
  position: relative;
  height: 37.5rem;
}

.infographics-are-a1 {
  align-self: stretch;
  flex: 1;
  position: relative;
}

.frame-3108-2,
.group-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-3108-2 {
  flex-direction: row;
  padding: 0 var(--padding-199xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  font-family: var(--font-suisse-intl);
}

.group-parent {
  flex-direction: column;
  gap: var(--gap-13xl);
}

.uxui11,
.wewe-can-help7 {
  position: relative;
  line-height: 3.13rem;
}

.wewe-can-help7 {
  align-self: stretch;
  font-size: var(--font-size-21xl);
  font-family: var(--font-suisse-intl-condensed);
}

.uxui11 {
  text-decoration: underline;
  text-wrap: nowrap;

  &.inactive {
    text-decoration: none;

    &:hover {
      cursor: default;
      color: var(--white-100);
    }
  }

  &:hover {
    cursor: pointer;
    color: var(--primary-color);
  }
}

.component-1210 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.component-1212 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.component-12-container,
.component-1213 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.component-12-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  row-gap: 0rem;
  font-size: var(--font-size-13xl);
  font-family: var(--font-suisse-intl-condensed);
  text-decoration-skip-ink: none;
  text-underline-offset: 0.4rem;
}

.component-1214 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.wewe-can-help-will-multiple-ty-parent2 {
  width: 80rem;
  background-color: var(--black-100);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-13xl);
  box-sizing: border-box;
}

.not-sure-give3 {
  position: relative;
  line-height: 4.69rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 70px;
}

.bewewewewestudiosk-63 {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: underline;
  color: var(--black-100);
  text-decoration-skip-ink: none;
  text-underline-offset: 0.4rem;

  &:hover {
    color: var(--primary-color);
  }
}

.desktop-mail-white-2-23 {
  width: 41.31rem;
  position: relative;
  height: 5.69rem;
  font-size: var(--font-size-51xl);
}

.frame-2988-23 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 var(--padding-13xl);
}

.take-a-look-43 {
  align-self: stretch;
  position: relative;
  line-height: 4.69rem;
  font-weight: 700;
}

.group-1003-2-icon3,
.group-1004-2-icon3,
.group-4-icon3 {
  width: 6.25rem;
  position: relative;
  height: 6.25rem;
}

.frame-2982-23,
.frame-3046-23 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-3046-23 {
  gap: 0 12px;
}

.frame-2982-23 {
  border-radius: var(--br-51xl);
}

.frame-2989-23 {
  align-self: stretch;
  align-items: flex-start;
  padding: 0 0 0 var(--padding-13xl);
  gap: var(--gap-base);
  font-size: var(--font-size-51xl);
}

.desktop-info-hovers-group,
.desktop-info-hovers3,
.frame-2989-23 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.desktop-info-hovers3 {
  width: 80rem;
  background-color: var(--white-100);
  align-items: center;
  padding: var(--padding-13xl) 0;
  box-sizing: border-box;
  gap: var(--gap-45xl);
  font-family: var(--font-suisse-intl-condensed-700);
  /* nejaky pojebany fix niecoho */
}

.desktop-info-hovers-group {
  align-items: flex-start;
}

.frame-wrapper10 {
  justify-content: flex-start;
  font-size: var(--font-size-56xl);
  color: var(--black-100);
}

.frame-parent33,
.frame-wrapper10,
.infographics {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.frame-parent33 {
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-13xl);
}

.infographics {
  align-self: stretch;
  justify-content: flex-start;
}

.frame-3091-5 {
  width: 80rem;
  /* gap: var(--gap-109xl); */
  text-align: center;
  font-size: var(--font-size-46xl);
}

.desktop-services-visual-design-inner,
.frame-3091-5,
.visual-desigb-perex-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.visual-desigb-perex-parent {
  width: 80rem;
  /* background-color: var(--black-100); */
  height: 100%;
  gap: var(--gap-121xl);
}

.desktop-services-visual-design-inner {
  /* position: absolute; */
  /* margin-top: 2.69rem; */
  left: 0;
  overflow: hidden;
}

.wewe14 {
  position: relative;
  cursor: pointer;
}

.component-11-icon9 {
  width: 0.88rem;
  position: relative;
  height: 2.69rem;
}

.design-studio9,
.slovak-visual9 {
  margin: 0;
}

.slovak-visual-design-container9 {
  position: relative;
}

.frame-2914-2,
.frame-68-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.frame-2914-2 {
  padding: var(--padding-8xs) 0 0 var(--padding-5xs);
  font-size: var(--font-size-3xs);
  font-family: var(--font-suisse-intl-mono);
}

.frame-68-2 {
  width: 17.06rem;
}

.story12 {
  color: var(--black-100);
}

.button-5-266 {
  position: relative;
}

.button-5-265 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button-5-268 {
  position: relative;
}

.button-5-267 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--primary-color);
}

.portfolio5 {
  color: var(--black-100);
}

.button-5-270 {
  position: relative;
}

.button-5-269 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.say-hi9 {
  color: var(--black-100);
}

.button-5-272 {
  position: relative;
}

.button-5-271 {
  justify-content: center;
}

.button-5-271,
.frame-2822-2,
.header-mobilheader-desktop-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.frame-2822-2 {
  align-self: stretch;
  flex: 1;
  justify-content: flex-end;
  gap: var(--gap-5xl);
  font-size: var(--font-size-11xl);
  color: var(--white-100);
}

.header-mobilheader-desktop-2 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white-100);
  width: 80rem;
  overflow: hidden;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  font-size: var(--font-size-14xl);
  color: var(--black-100);
}

.desktop-services-visual-design {
  width: 100%;
  position: relative;
  background-color: var(--black-100);
  height: 100%;
  overflow-y: auto;
  text-align: left;
  font-size: var(--font-size-191xl);
  color: var(--white-100);
  font-family: var(--font-suisse-intl-condensed-700);
}

.group-1004-3-2-icon1 {
  width: 6.25rem;
  position: relative;

  &:hover {
    /* https://cssfilterconverter.com/ */
    filter: brightness(0) saturate(100%) invert(13%) sepia(100%) saturate(6860%) hue-rotate(281deg) brightness(103%) contrast(116%);
  }
}