/* LANDING - DESKTOP */
#desktop-landing {
  .we-make-things {
    margin: 0;
    font-size: 9.375rem;
    white-space: pre-wrap;
  }

  .we-make-things3 {
    margin: 5px 0;
  }

  .we-make-things1 {
    margin: 0;
    white-space: pre-wrap;
    font-size: 7.5rem;
  }

  .we-make-things-container3 {
    position: absolute;
    top: 5rem;
    left: calc(50% - 597px);
    line-height: 9.69rem;
  }

  .desktop-claim-variation {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--white-100);
    width: 80rem;
    height: 39.94rem;

    &.one {
      background-color: var(--white-100);
      -webkit-text-stroke: 2px #000;

      .contact-wewe1 {
        -webkit-text-stroke: 0px var(--primary-color);
      }
  }

    &.two {
      color: var(--black-100);
      background-color: var(--white-100);
    }

    &.three {
      background-color: var(--black-100);
      color: var(--white-100);
    }
  }

  .landing-claim {
    width: 80rem;
    position: relative;
    height: 39.94rem;
  }

  .user-friendly-interfaces-to {
    align-self: stretch;
    flex: 1;
    position: relative;
    line-height: 3.44rem;
    font-weight: 700;
  }

  .claim1,
  .desktop-landing-text {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .desktop-landing-text {
    width: 80rem;
    flex-direction: row;
    padding: 0 var(--padding-base);
    box-sizing: border-box;
    text-align: left;
    font-size: var(--font-size-37xl);
    color: var(--black-100);
  }

  .claim1 {
    flex-direction: column;
    gap: var(--gap-29xl);
  }

  .we-design12 {
    width: 38.5rem;
    position: relative;
    line-height: 6.25rem;
    display: inline-block;
    flex-shrink: 0;
  }

  .we-design-wrapper2 {
    align-self: stretch;
    height: 12.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .website-app-uiux-container5 {
    width: 36.25rem;
    position: relative;
    font-weight: 500;
    white-space: pre-wrap;
    display: inline-block;
    height: 8.75rem;
    flex-shrink: 0;
  }

  .website-app-uiux-logo-wrapper2 {
    align-self: stretch;
    height: 7.38rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--font-size-xl);
    font-family: var(--font-suisse-intl);
  }

  .secondary-24 {
    position: relative;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-underline-offset: 0.2rem;
  }

  .primary-button-24 {
    align-self: stretch;
    flex: 1;
    border-radius: var(--br-71xl);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    font-size: var(--font-size-5xl);
    color: var(--primary-color);
    font-family: var(--font-suisse-intl-mono);
  }

  .desktop-landing-service-hovers {
    width: 38.5rem;
    border-radius: var(--br-9xs);
    border: 2px solid var(--black-100);
    box-sizing: border-box;
    height: 27.88rem;
    flex-direction: column;
    padding: var(--padding-base) 0 var(--padding-base) var(--padding-base);

    &:active,
    &:hover {
      cursor: pointer;
      background-color: var(--primary-color);

      .secondary-24,
      .we-design-wrapper2 {
        color: var(--white-100)
      }
    }
  }

  .desktop-landing-service-hovers,
  .desktop-landing-service-hovers-parent,
  .services2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }

  .desktop-landing-service-hovers-parent {
    width: 78rem;
    flex-direction: row;
  }

  .services2 {
    flex-direction: column;
    text-align: left;
    font-size: var(--font-size-101xl);
    color: var(--black-100);
    margin-bottom: 5rem;
  }

  .frame-3026-2 {
    align-items: center;
    gap: var(--gap-61xl);
  }

  .desktop-landing1,
  .frame-3026-2,
  .frame-3026-2-parent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .frame-3026-2-parent {
    align-items: flex-start;
    gap: var(--gap-61xl);
  }

  .desktop-landing1 {
    /* position: absolute; */
    /* margin-top: 2.69rem; */
    left: 0;
    background-color: var(--white-100);
    width: 80rem;
    overflow: hidden;
    align-items: center;
  }

  .desktop-landing {
    width: 100%;
    position: relative;
    height: 100%;
    text-align: center;
    font-size: var(--font-size-131xl);
    color: transparent;
    font-family: var(--font-suisse-intl-condensed-700);
    gap: 2.69rem;
  }

  .contact2 {
    margin: 0;
  }

  .contact-wewe1 {
    width: 7rem;
    position: relative;
    line-height: 1.63rem;
    display: -webkit-inline-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    flex-shrink: 0;
  }

  .landing-button-v11 {
    text-decoration: none;
    position: absolute;
    height: 15.65%;
    width: 14.06%;
    top: 51.33%;
    right: 11.95%;
    bottom: 33.02%;
    left: 73.98%;
    border-radius: var(--br-61xl);
    background-color: var(--primary-color);
    border: 2px solid var(--white-100);
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: rotate(22deg);
    transform-origin: 0 0;
    text-align: center;
    font-size: var(--font-size-5xl);
    color: var(--white-100);
    font-family: var(--font-suisse-intl-mono);
    z-index: 9;

    &:hover {
      cursor: pointer;
      color: var(--primary-color);
      background-color: var(--white-100);
      border: 2px solid var(--black-100);
    }
  }
}

/* LANDING - MOBILE */
#mobile-landing {
  .we-make-things-container3 {
    position: absolute;
    top: 9.75rem;
    left: calc(50% - 158px);
    line-height: 2.5rem;
    /* -webkit-text-stroke: 1px #000; */
  }

  .user-friendly-interfaces-to-container1 {
    position: absolute;
    top: 31.25rem;
    left: calc(50% - 140px);
    font-size: var(--font-size-7xl);
    line-height: 1.63rem;
    color: var(--black-100);

    &.one {
      background-color: var(--white-100);
      -webkit-text-stroke: 0px;
    }

    &.two {
      color: var(--black-100);
      background-color: var(--white-100);
    }

    &.three {
      background-color: var(--black-100);
      color: var(--white-100);
    }

  }

  .mobil-claim-variation {
    width: 22.5rem;
    position: relative;
    background-color: var(--white-100);
    height: 47.31rem;
    overflow: hidden;
    flex-shrink: 0;

    &.one {
      background-color: var(--white-100);
      -webkit-text-stroke: 1px #000;
    }

    &.two {
      background-color: var(--white-100);
      color: var(--black-100);
    }

    &.three {
      background-color: var(--black-100);
      color: var(--white-100);
    }

  }

  .we-design-31 {
    position: relative;
    line-height: 3.44rem;
  }

  .frame-3073-21 {
    align-self: stretch;
    height: 6.99rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .website-app-uiux-container9 {
    width: 20.26rem;
    position: relative;
    font-weight: 500;
    white-space: pre-wrap;
    display: inline-block;
    flex-shrink: 0;
  }

  .frame-3072-21 {
    flex: 1;
    background-color: var(--white-100);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--font-size-3xs);
    font-family: var(--font-suisse-intl);
  }

  .secondary-41 {
    position: relative;
    text-decoration: underline;
    font-size: 1.5rem;
  }

  .primary-button-41 {
    align-self: stretch;
    flex: 1;
    border-radius: var(--br-31xl);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    font-size: var(--font-size-xl);
    color: var(--primary-color);
    font-family: var(--font-suisse-intl-mono);
  }

  .mobil-we-design-hover {
    width: 21.5rem;
    border-radius: var(--br-9xs);
    background-color: var(--white-100);
    border: 1px solid var(--black-100);
    box-sizing: border-box;
    height: 15.56rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-4xs) 0rem var(--padding-4xs) var(--padding-4xs);
    gap: var(--gap-4xs);
    cursor: pointer;
  }

  .frame-3072-22 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--font-size-3xs);
    font-family: var(--font-suisse-intl);
  }

  .fronted-analytics {
    margin: 0;
  }

  .fronted-analytics-onefour {
    font-size: 0.875rem;
    margin: 0;
  }

  .fronted-analytics-h2 {
    margin: 0;
    white-space: pre-wrap;
    font-weight: 700;
    font-size: 4.188rem;
  }

  .mobil-we-design-hover-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    text-align: left;
    font-size: var(--font-size-48xl);
    color: var(--black-100);
    margin-bottom: 3rem;
  }

  .mobil-claim-variation-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-29xl);
  }

  .services10 {
    position: relative;
    text-decoration: underline;
    line-height: 5.63rem;
    text-transform: lowercase;
  }

  .portfolio9 {
    position: relative;
    text-decoration: underline;
    line-height: 6.25rem;
    text-transform: lowercase;
  }

  .story16 {
    width: 7.88rem;
    position: relative;
    text-decoration: underline;
    line-height: 5.63rem;
    text-transform: lowercase;
    display: inline-block;
  }

  .services-group {
    align-self: stretch;
    width: 22.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xs) 0rem 0rem var(--padding-base);
    box-sizing: border-box;
  }

  .frame-wrapper15 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .rectangle-1160-2-24 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    background-color: var(--black-100);
  }

  .wewe-24 {
    position: absolute;
    top: 15.09%;
    left: 1.67%;
  }

  .wewe-3-icon4 {
    position: absolute;
    height: 69.81%;
    width: 6.94%;
    top: 15.09%;
    right: 29.17%;
    bottom: 15.09%;
    left: 63.89%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }

  .mobil-footer-logo-animacia-2-24 {
    width: 22.5rem;
    position: relative;
    height: 6.63rem;
    font-size: var(--font-size-83xl-5);
  }

  .frame-parent45 {
    position: absolute;
    top: 0rem;
    left: 0rem;
    background-color: var(--black-100);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }

  .mobil-footer1 {
    width: 22.5rem;
    position: relative;
    height: 31.25rem;
    text-align: left;
    font-size: var(--font-size-41xl);
    color: var(--white-100);
  }

  .frame-parent44 {
    /* position: absolute; */
    top: 0rem;
    left: 0rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-29xl);
  }

  .mobile-landing {
    width: 100%;
    position: relative;
    background-color: var(--white-100);
    height: 100%;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-21xl);
    color: transparent;
    font-family: var(--font-suisse-intl-condensed-700);
  }
}