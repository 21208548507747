/* PORTFOLIO - DESKTOP */
#desktop-portfolio {
  .devil-is-in {
    margin: 0;
  }

  .devil-is-in-h1 {
    margin: 0;
    white-space: pre-wrap;
    font-size: 13.125rem;
    font-weight: 700;
  }

  .wewe-is-here-2-container {
    position: relative;
    line-height: 11.88rem;
  }

  .frame-3163-3,
  .frame-3168-3 {
    display: flex;
    justify-content: flex-start;
  }

  .frame-3163-3 {
    width: 78rem;
    flex-direction: row;
    align-items: center;
  }

  .frame-3168-3 {
    flex-direction: column;
    align-items: flex-end;
  }

  .component-10-2-2-2-2-2-2-2-2-2-icon {
    width: 24.69rem;
    position: relative;
    height: 29.69rem;
    object-fit: cover;
    border-radius: 0.25rem;
  }

  .wewe-design-studio-container1 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  /* Adding space between <p> elements */
.devil-is-in {
  margin-bottom: 5px;  /* Space below each paragraph */
}

.wewe-design-studio-container1 > .devil-is-in:last-child {
  margin-bottom: 0;    /* No space below the last paragraph */
}

  .wewe-design-studio-container {
    width: 24.63rem;
    position: relative;
    display: flex;
    align-items: center;
  }

  .frame-3205-6 {
    /* height: 33.56rem; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 var(--padding-5xs);
    box-sizing: border-box;
    gap: var(--gap-5xs);
    cursor: pointer;
  }

  .frame-3088-3,
  .frame-3088-3-child {
    width: 24.69rem;
    border-radius: var(--br-9xs);
    overflow: hidden;
    flex-shrink: 0;
  }

  .frame-3088-3-child {
    position: relative;
    height: 29.69rem;
  }

  .frame-3088-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-8xs);
    cursor: pointer;
  }

  .div {
    position: absolute;
    height: 303.16%;
    width: 318.99%;
    top: -101.68%;
    right: -109.37%;
    bottom: -101.47%;
    left: -109.62%;
  }

  .frame-3088-4 {
    width: 24.69rem;
    height: 33.56rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-8xs);
    cursor: pointer;
  }

  .frame-3205-6-parent {
    /* width: 78rem; */
    /* display: flex; */
    /* flex-direction: row; */
    /* align-items: flex-start; */
    /* justify-content: center; */
    /* gap: var(--gap-base); */

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 15px;
    gap: 2rem 1rem;
  }

  .frame-wrapper6 {
    font-size: var(--font-size-sm);
    font-family: var(--font-suisse-intl-mono);
    margin-bottom: 5rem;
  }

  .frame-3168-3-parent {
    gap: var(--gap-91xl);
  }

  .desktop-portfolio-inner,
  .frame-3168-3-parent,
  .frame-parent11,
  .frame-wrapper5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .desktop-portfolio-inner,
  .frame-parent11 {
    align-items: center;
  }

  .frame-parent11 {
    gap: var(--gap-61xl);
    padding: 0 var(--padding-base);
  }

  .desktop-portfolio-inner {
    /* position: absolute; */
    /* margin-top: 2.69rem; */
    left: 0;
  }

  .wewe9 {
    position: relative;
    cursor: pointer;
  }

  .component-11-icon5 {
    width: 0.88rem;
    position: relative;
    height: 2.69rem;
  }

  .slovak-visual-design-container5 {
    position: relative;
  }

  .frame-2914-6,
  .frame-68-6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .frame-2914-6 {
    padding: var(--padding-8xs) 0 0 var(--padding-5xs);
    font-size: var(--font-size-3xs);
    font-family: var(--font-suisse-intl-mono);
  }

  .frame-68-6 {
    width: 17.06rem;
  }

  .story8 {
    color: var(--black-100);
  }

  .button-5-233 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .button-5-237 {
    justify-content: center;
    cursor: pointer;
    color: var(--primary-color);
  }

  .button-5-237,
  .frame-2822-6,
  .header-mobilheader-desktop-6 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .frame-2822-6 {
    align-self: stretch;
    flex: 1;
    justify-content: flex-end;
    gap: var(--gap-5xl);
    font-size: var(--font-size-11xl);
    color: var(--white-100);
  }

  .header-mobilheader-desktop-6 {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--white-100);
    width: 80rem;
    overflow: hidden;
    justify-content: flex-start;
    padding: 0 var(--padding-5xs);
    box-sizing: border-box;
    gap: var(--gap-3xs);
    font-size: var(--font-size-14xl);
  }

  .desktop-portfolio {
    width: 100%;
    position: relative;
    background-color: var(--white-100);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
    font-size: var(--font-size-191xl);
    color: var(--black-100);
    font-family: var(--font-suisse-intl-condensed-700);
  }

}

/* PORTFOLIO - MOBILE */
#mobile-portfolio {
  .devil-is-in1 {
    margin: 0;
  }

  .devil-is-in1 {
    margin-bottom: 5px;  /* Space below each paragraph */
  }
  
  .wewe-design-studio-container > .devil-is-in1:last-child {
    margin-bottom: 0;    /* No space below the last paragraph */
  }

  .wewe-is-here-container {
    position: relative;
    line-height: 3.63rem;
    font-family: var(--font-suisse-intl-condensed-700);
  }

  .wewe-is-here-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem var(--padding-5xs);
  }

  .component-10-2-2-2-2-2-2-2-2-2-icon2 {
    width: 100%;
    position: relative;
    height: 26.14rem;
    object-fit: cover;
    border-radius: 0.219rem;
  }

  .frame-3205-2 {
    width: 21.5rem;
    /* height: 29.56rem; */
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.44rem;
  }

  .b7c69c134383587242079132438d50-icon4 {
    width: 21.74rem;
    position: relative;
    border-radius: 3.52px;
    height: 26.14rem;
    object-fit: cover;
  }

  .frame-3214-3-21 {
    position: absolute;
    top: 0rem;
    left: 0rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .frame-3212-5-2 {
    width: 21.74rem;
    position: relative;
    border-radius: 3.52px;
    height: 26.14rem;
  }

  .motion-animation {
    align-self: stretch;
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .frame-3212-5-2-parent {
    width: 21.5rem;
    border-radius: 3.52px;
    height: 30.34rem;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-8xs-4);
  }

  .gif-1-2-icon {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    border-radius: 3.52px;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }

  .gif-2-2-21 {
    position: absolute;
    height: 303.16%;
    width: 318.98%;
    top: -87.38%;
    right: -117.47%;
    bottom: -115.78%;
    left: -101.51%;
  }

  .gif-1-6-2 {
    width: 21.5rem;
    position: relative;
    height: 26.14rem;
  }

  .uiux-web-design {
    align-self: stretch;
    position: relative;
    display: flex;
    align-items: center;
    height: 0.86rem;
    flex-shrink: 0;
  }

  .frame-3088-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-8xs-4);
  }

  .frame-3205-2-parent {
    width: 22.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
    font-size: var(--font-size-sm);
    font-family: var(--font-suisse-intl-mono);
  }

  .frame-parent45 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-45xl);
  }

  .frame-3060-2-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .services-9 {
    position: relative;
    text-decoration: underline;
    line-height: 5.63rem;
    text-transform: lowercase;
  }

  .portfolio-9 {
    position: relative;
    text-decoration: underline;
    line-height: 6.25rem;
    text-transform: lowercase;
  }

  .story-9 {
    width: 7.88rem;
    position: relative;
    text-decoration: underline;
    line-height: 5.63rem;
    text-transform: lowercase;
    display: inline-block;
  }

  .frame-2821-9 {
    align-self: stretch;
    width: 22.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xs) 0rem 0rem var(--padding-base);
    box-sizing: border-box;
  }

  .frame-2916-9 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .rectangle-1160-2-25 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    background-color: var(--black-100);
  }

  .wewe-25 {
    position: absolute;
    top: 15.09%;
    left: 1.67%;
  }

  .wewe-3-icon5 {
    position: absolute;
    height: 69.81%;
    width: 6.94%;
    top: 15.09%;
    right: 29.17%;
    bottom: 15.09%;
    left: 63.89%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }

  .mobil-footer-logo-animacia-2-25 {
    width: 22.5rem;
    position: relative;
    height: 6.63rem;
    font-size: var(--font-size-83xl-5);
  }

  .frame-2917-9 {
    position: absolute;
    top: 0rem;
    left: 0rem;
    background-color: var(--black-100);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }

  .mobil-landing-9 {
    width: 22.5rem;
    position: relative;
    height: 31.25rem;
    font-size: var(--font-size-41xl);
    color: var(--white-100);
  }

  .frame-3060-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-base) 0rem 0rem;
    gap: var(--gap-61xl);
  }

  .mobile-portfolio {
    width: 100%;
    position: relative;
    background-color: var(--white-100);
    height: 100%;
    text-align: left;
    font-size: var(--font-size-39xl);
    color: var(--black-100);
    font-family: var(--font-suisse-intl-condensed);
    padding-bottom: var(--gap-61xl);
  }

}