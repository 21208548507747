/* okey */

/* CONTACT - PANEL - DESKTOP */
  .you-ask-wewe1 {
      position: relative;
      line-height: 75px;
      text-transform: uppercase;
      font-weight: 700;
    }
    .bewewewewestudiosk-5 {
      position: absolute;
      top: 0%;
      left: 0%;
      text-decoration: underline;
      color: var(--white-100);
      font-weight: 700;

      text-decoration-skip-ink: none;
      text-underline-offset: 0.4rem;

      &:hover{
        color:var(--primary-color);
      }
    }
    .desktop-mail-black-2-2 {
      width: 745px;
      position: relative;
      height: 91px;
    }
    .you-ask-wewe-answers-parent {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0px 0px 0px var(--padding-13xl);
    }
    .take-a-look-3 {
      align-self: stretch;
      position: relative;
      line-height: 75px;
    }
    .group-1004-3-2-icon {
      width: 4.13rem;
      position: relative;
      cursor: pointer;

      &:hover {
        /* https://cssfilterconverter.com/ */
        filter: brightness(0) saturate(100%) invert(13%) sepia(100%) saturate(6860%) hue-rotate(281deg) brightness(103%) contrast(116%);
      }
    }

    .group-1004-3-2-parent {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0 12px;
    }
    .frame-wrapper10 {
      border-radius: var(--br-51xl);
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .take-a-look-3-parent {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0px 0px 0px var(--padding-13xl);
      gap: var(--gap-base);
    }
    .property-1default-11 {
      width: 1280px;
      background-color: var(--black-100);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: var(--padding-13xl) 0px;
      box-sizing: border-box;
      gap: var(--gap-45xl);
      text-align: left;
      font-size: var(--font-size-51xl);
      color: var(--white-100);
      font-family: var(--font-suisse-intl-condensed-700);
    }

    .designwewestudiosk-16-32 {
      position: absolute;
      top: 0%;
      left: 0%;
      text-decoration: underline;
      color:var(--white-100);
  
      /* &:hover{
          color:var(--primary-color);
        } */
    }
    .mobil-mail-black-22 {
      width: 19.88rem;
      position: relative;
      height: 2.63rem;
      font-size: 30px;
    }
    .frame-2988-62 {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: var(--gap-5xs);
    }
    .take-a-look-162 {
      width: 14.56rem;
      position: relative;
      line-height: 2.13rem;
      display: inline-block;
    }
    .group-1004-15-icon2 {
      width: 2.91rem;
      position: relative;
      height: 2.91rem;
  
      /* &:hover {
          filter: brightness(0) saturate(100%) invert(13%) sepia(100%) saturate(6860%) hue-rotate(281deg) brightness(103%) contrast(116%);
        } */
    }
    
    .frame-2982-152 {
      border-radius: 32.59px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .take-a-look-16-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: var(--gap-5xs);
      font-size: 34px;
    }
    .mobil-service-info2 {
      width: 22.5rem;
      background-color: var(--black-100);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: var(--padding-base);
      box-sizing: border-box;
      gap: var(--gap-45xl);
      /* margin-top: var(--gap-45xl); */
      text-align: left;
      font-size: var(--font-size-29xl);
      font-family: var(--font-suisse-intl-condensed-700);
    }