#desktop-fullscreen {

  .fill-width {
    width: -webkit-fill-available;
    width: 100vw;
  }

  .gif-punctuation-1-icon {
    width: 100vw;
    position: relative;
    object-fit: cover;
    mix-blend-mode: multiply;
  }

  .gif-punctuation-1-parent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .frame-3129-3,
  .fullscreen-31 {
    position: absolute;
    top: 0;
    left: 0;
  }

  .frame-3129-3 {
    background-color: var(--white-100);
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .fullscreen-31 {
    line-height: 1.56rem;
  }

  .fullscreen {
    position: fixed;
    top: 1.13rem;
    left: 1rem;
    /* width: 5.81rem; */
    height: 1.56rem;
    cursor: pointer;
    z-index: 100;
    mix-blend-mode: difference;
  }

  .rectangle-1169-3 {
    position: absolute;
    top: 2.69rem;
    left: 50.38rem;
    width: 29.63rem;
    height: 48.81rem;
  }

  .desktop-portfolio-detail-1-zoo {
    width: 100%;
    /* position: relative; */
    background-color: var(--white-100);
    /* height: 491.13rem; */
    overflow-y: auto;
    text-align: left;
    font-size: var(--font-size-6xl);
    color: var(--white-100);
    font-family: var(--font-suisse-intl-condensed);
    overflow: hidden;
  }
}