#mobile-menu {

    #mainmenu-mobile {
        display: contents;
        text-decoration-skip-ink: none;
        text-underline-offset: 0.4rem;

        a {
            color: var(--black-100);
            font-weight: 700;
            text-decoration: underline;
            line-height: 5.625rem;
            text-transform: lowercase;

            /* &:hover {
                color: var(--primary-color);
            } */

            &.active {
                color: var(--primary-color);

                &:before,
                &:after {
                    content: "!";
                    color: var(--primary-color);
                }
            }
        }
    }

    .services-2 {
        position: relative;
        text-decoration: underline;
        line-height: 5.625rem;
        text-transform: lowercase;
    }

    .portfolio-2 {
        position: relative;
        text-decoration: underline;
        line-height: 6.25rem;
        text-transform: lowercase;
    }

    .frame-2821-2 {
        height: 31.438rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: var(--padding-5xs) 0rem 0rem var(--padding-base);
        box-sizing: border-box;
    }

    .rectangle-1157-3 {
        position: absolute;
        top: 2.75rem;
        left: 0rem;
        background-color: var(--black-100);
        width: 3.856rem;
        height: 0.506rem;
        transform: rotate(-45.06deg);
        transform-origin: 0 0;
    }

    .rectangle-1158-3 {
        position: absolute;
        top: 0rem;
        left: 0.375rem;
        background-color: var(--black-100);
        width: 3.856rem;
        height: 0.506rem;
        transform: rotate(45.06deg);
        transform-origin: 0 0;
    }

    .rectangle-1157-3-parent {
        width: 3.1rem;
        position: relative;
        height: 3.113rem;
    }

    .header-mobil-2-inner {
        align-self: stretch;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        padding: 0rem var(--padding-base) 0rem 0rem;
    }

    .header-mobil-2 {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0rem 0rem var(--padding-5xl) var(--padding-base);
    }

    .frame-2821-2-parent {
        width: 22.5rem;
        height: 37.313rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .mobil-menu {
        width: 22.5rem;
        /* position: relative; */
        background-color: var(--secondary);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        max-width: 100%;
        /* min-height: 100vh; */
        /* min-height: 100lvh; */
        overflow: auto;
        text-align: left;
        font-size: var(--font-size-41xl);
        color: var(--black-100);
        /* position: fixed; */
        bottom: 0;
        font-family: var(--font-suisse-intl-condensed-700);
}

    .portalPopupOverlay {
        display: flex;
        flex-direction: column;
        position: fixed;
        inset: 0;
    }

}