/* SERVICES - DESKTOP */
#desktop-services {

  .services-h12 {
    position: relative;
    line-height: 11.88rem;
    font-size: var(--font-size-191xl);
    margin: 0;
    white-space: pre-wrap;
  }

  .services-h11 {
    width: 78rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .services-h21 {
    width: 77.75rem;
    position: relative;
    line-height: 3.5rem;
    display: inline-block;
    flex-shrink: 0;
    white-space: pre-wrap;
    font-weight: 700;
  }

  .services-h2 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    font-size: var(--font-size-37xl);
  }

  .frame-3168-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: var(--gap-21xl);
  }

  .we13 {
    margin: 0;
  }

  .design9 {
    margin: 0;
  }

  .we-design16 {
    width: 37.5rem;
    position: relative;
    line-height: 8.13rem;
    display: none;
  }

  .we-design-51 {
    width: 30.31rem;
    position: relative;
    text-decoration: underline;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .secondary-button-91 {
    border-radius: var(--br-78xl);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .secondary-button-9-container {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-size: var(--font-size-21xl);
    font-family: var(--font-suisse-intl-mono);
  }

  .services-box-we-design {
    width: 38.5rem;
    border-radius: var(--br-xs);
    background-color: var(--white-100);
    border: 2px solid var(--black-100);
    box-sizing: border-box;
    height: 27.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--padding-27xl) 0rem var(--padding-base) var(--padding-base);
  }

  .we14 {
    margin: 0;
  }

  .design10 {
    margin: 0;
  }

  .we-design17 {
    width: 37.5rem;
    position: relative;
    line-height: 8.13rem;
    display: none;
  }

  .we-brand1 {
    width: 30.31rem;
    position: relative;
    text-decoration: underline;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .secondary-button-101 {
    border-radius: var(--br-78xl);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .frame-3071-21 {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-size: var(--font-size-21xl);
    font-family: var(--font-suisse-intl-mono);
  }

  .services-box-we-brand {
    width: 38.5rem;
    border-radius: var(--br-xs);
    background-color: var(--white-100);
    border: 2px solid var(--black-100);
    box-sizing: border-box;
    height: 27.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--padding-27xl) 0rem var(--padding-base) var(--padding-base);
  }

  .services-box-we-design-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-lg);
  }

  .we15 {
    margin: 0;
  }

  .design11 {
    margin: 0;
  }

  .we-design18 {
    width: 37.5rem;
    position: relative;
    line-height: 8.13rem;
    display: none;
  }

  .we-develop1 {
    width: 30.31rem;
    position: relative;
    text-decoration: underline;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .secondary-button-111 {
    border-radius: var(--br-78xl);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .frame-3071-31 {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-size: var(--font-size-21xl);
    font-family: var(--font-suisse-intl-mono);
  }

  .services-box-we-develop {
    width: 38.5rem;
    border-radius: var(--br-xs);
    background-color: var(--white-100);
    border: 2px solid var(--black-100);
    box-sizing: border-box;
    height: 27.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--padding-27xl) 0rem var(--padding-base) var(--padding-base);
  }

  .we16 {
    margin: 0;
  }

  .design12 {
    margin: 0;
  }

  .we-design19 {
    width: 37.5rem;
    position: relative;
    line-height: 8.13rem;
    display: none;
  }

  .we-consult1 {
    width: 30.31rem;
    position: relative;
    text-decoration: underline;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .secondary-button-121 {
    border-radius: var(--br-78xl);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .frame-3071-41 {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-size: var(--font-size-21xl);
    font-family: var(--font-suisse-intl-mono);
  }

  .services-box-we-consult {
    width: 38.5rem;
    border-radius: var(--br-xs);
    background-color: var(--white-100);
    border: 2px solid var(--black-100);
    box-sizing: border-box;
    height: 27.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--padding-27xl) 0rem var(--padding-base) var(--padding-base);
  }

  .services-box-we-develop-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-lg);
  }

  .frame-parent34 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-lg);
    font-size: var(--font-size-131xl);
  }

  .frame-3168-2-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-61xl);
  }

  .desktop-info-hovers-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .frame-parent33 {
    /* position: absolute; */
    height: 100%;
    /* margin-top: 2.69rem; */
    left: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-61xl);
  }

  .desktop-services {
    width: 100%;
    position: relative;
    background-color: var(--white-100);
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    text-align: left;
    font-size: var(--font-size-191xl);
    color: var(--black-100);
    font-family: var(--font-suisse-intl-condensed-700);
  }

}

/* SERVICES - MOBILE */
#mobile-services {
  .services-h1 {
    position: relative;
    line-height: 3.625rem;
  }

  .unleashing-personal {
    margin: 0;
    white-space: pre-wrap;
  }

  .services-text1 {
    width: 19.5rem;
    position: relative;
    line-height: 2.5rem;
    display: inline-block;
  }

  .services-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--font-size-21xl);
  }

  .services-h1-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xs) 0rem 0rem;
    gap: var(--gap-base);
    font-family: var(--font-suisse-intl-condensed-700);
  }

  .you-will-slaywith {
    width: 19.44rem;
    position: relative;
    line-height: 3.75rem;
    display: inline-block;
    white-space: pre-wrap;
    font-family: var(--font-suisse-intl-condensed-700);
  }

  .you-will-slaywith-our-design-wrapper {
    background-color: var(--black-100);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .we-specialize-in {
    align-self: stretch;
    flex: 1;
    position: relative;
    font-size: var(--font-size-xl);
    font-family: var(--font-suisse-intl);
    white-space: pre-wrap;
  }

  .secondary-5 {
    position: relative;
    text-decoration: underline;
    font-size: 2.5rem;
  }

  .secondary-button-5 {
    border-radius: var(--br-78xl);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--font-size-13xl);
    color: var(--primary-color);
    font-family: var(--font-suisse-intl-mono);
  }

  .we-design-panel {
    width: 21.63rem;
    border-radius: var(--br-xs);
    background-color: var(--black-100);
    /* height: 34.63rem; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-lg);
    cursor: pointer;
  }

  .frame-3065-2 {
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .blank-line7 {
    margin: 0;
    font-size: var(--font-size-5xl);
  }

  .we-specialize-in-container {
    align-self: stretch;
    flex: 1;
    position: relative;
    font-family: var(--font-suisse-intl);
    font-size: var(--font-size-xl);
  }

  .we-design-panel-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    font-size: var(--font-size-41xl);
    color: var(--white-100);
  }

  .ask-away-13-21 {
    position: relative;
    line-height: 3rem;
    text-transform: uppercase;
  }

  .designwewestudiosk-24-21 {
    position: relative;
    font-size: var(--font-size-13xl);
    text-decoration: underline;

    &:hover {
      color: var(--primary-color);
    }
  }

  .ask-away-13-2-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }

  .take-a-look-21-23 {
    width: 20.06rem;
    position: relative;
    line-height: 2rem;
    display: inline-block;
  }

  .group-1004-19-2-icon3 {
    width: 2.94rem;
    position: relative;
    height: 2.94rem;

    /* &:hover {
      filter: brightness(0) saturate(100%) invert(13%) sepia(100%) saturate(6860%) hue-rotate(281deg) brightness(103%) contrast(116%);
    } */
  }

  .frame-3046-19-23 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }

  .frame-2982-20-23 {
    border-radius: var(--br-14xl);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .take-a-look-21-2-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    font-size: var(--font-size-13xl);
  }

  .frame-3149-13-21 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-45xl);
  }

  .mobil-info-hover1 {
    width: 22.5rem;
    background-color: var(--white-100);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xl) var(--padding-base) var(--padding-13xl);
    box-sizing: border-box;
  }

  .frame-2930-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .mobil-info-hover-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--font-size-29xl);
  }

  .frame-2931-2 {
    /* position: absolute; */
    top: 0rem;
    left: 0rem;
    width: 22.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-45xl);
    /* padding-bottom: var(--gap-45xl); */
  }

  .mobile-services {
    width: 100%;
    position: relative;
    background-color: var(--white-100);
    height: 100%;
    text-align: left;
    font-size: var(--font-size-39xl);
    color: var(--black-100);
    font-family: var(--font-suisse-intl-condensed);
  }

}