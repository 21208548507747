@font-face {
  font-family: "Suisse Intl Condensed";
  src: url("./fonts/SuisseIntlCond-Regular-WebS.woff");
  font-weight: 400;
}

@font-face {
  font-family: "Suisse Intl Condensed 600";
  src: url("./fonts/SuisseIntlCond-Semibold-WebS.woff");
  font-weight: 600;
}

@font-face {
  font-family: "Suisse Intl Condensed 700";
  src: url("./fonts/SuisseIntlCond-Bold-WebS.woff");
  font-weight: 700;
}

@font-face {
  font-family: "Suisse Intl Small";
  src: url("./fonts/SuisseIntl-Regular-WebS.woff");
  font-weight: 400;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("./fonts/SuisseIntl-Regular-WebS.woff") format('woff'), 
       url("./fonts/SuisseIntl-Regular-WebM.woff") format('woff'), 
       url("./fonts/SuisseIntl-Regular-WebS.ttf") format('ttf');
}

@font-face {
  font-family: "Suisse Intl Small 500";
  src: url("./fonts/SuisseIntl-SemiBold-WebS.woff");
  font-weight: 500;
}

@font-face {
  font-family: "Suisse Intl 500";
  src: url("./fonts/SuisseIntl-SemiBold-WebS.woff"), url("./fonts/SuisseIntl-SemiBold-WebM.woff");
  font-weight: 500;
}

@font-face {
  font-family: "Suisse Intl Mono";
  src: url("./fonts/SuisseIntlMono-Regular-WebS.woff");
  font-weight: 400;
}

@font-face {
  font-family: "Suisse Intl Mono 700";
  src: url("./fonts/SuisseIntlMono-Bold-WebS.woff");
  font-weight: 700;
}

@keyframes fromBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes toBottom {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes fromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes toTop {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes fromTop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

body {
  /* overflow: hidden; */
  overflow-x: hidden;
  margin: 0;
  line-height: normal;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  background-color: var(--black-100);
}

:root {

  -webkit-tap-highlight-color: transparent !important;
  -webkit-overflow-scrolling: touch;

  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 5px;
    background-color: var(--white-100);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    /* background-image: linear-gradient(180deg, var(--primary-color) 0%, var(--secondary) 100%); */
    background-color: #000;
    /* background-color: #ff0000; */
    /* box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5); */
    border-radius: 0px;
    height: 3rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  /* fonts */
  --font-suisse-intl-condensed: "Suisse Intl Condensed";
  --font-suisse-intl-condensed-600: "Suisse Intl Condensed 600";
  --font-suisse-intl-condensed-700: "Suisse Intl Condensed 700";
  --font-suisse-intl-mono: "Suisse Intl Mono";
  --font-suisse-intl-mono-700: "Suisse Intl Mono 700";
  --font-suisse-intl: "Suisse Intl";
  --font-suisse-intl-small: "Suisse Intl Small";
  --font-suisse-intl-500: "Suisse Intl 500";
  --font-suisse-intl-small-500: "Suisse Intl Small 500";


  /* font sizes */
  --font-size-3xs: 0.63rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.13rem;
  --font-size-xl: 1.25rem;
  --font-size-5xl: 1.5rem;
  --font-size-6xl: 1.56rem;
  --font-size-7xl: 1.63rem;
  --font-size-11xl: 1.88rem;
  --font-size-13xl: 2rem;
  --font-size-14xl: 2.06rem;
  --font-size-16xl: 2.19rem;
  --font-size-21xl: 2.5rem;
  --font-size-29xl: 3rem;
  --font-size-31xl: 3.1rem;
  --font-size-37xl: 3.5rem;
  --font-size-39xl: 3.63rem;
  --font-size-41xl: 3.75rem;
  --font-size-46xl: 4.06rem;
  --font-size-48xl: 4.19rem;
  --font-size-49xl: 4.31rem;
  --font-size-51xl: 4.375rem;
  --font-size-56xl: 4.69rem;
  --font-size-71xl: 5.63rem;
  --font-size-83xl-5: 6.41rem;
  --font-size-91xl: 6.88rem;
  --font-size-101xl: 7.5rem;
  --font-size-131xl: 9.38rem;
  --font-size-191xl: 13.13rem;

  /* Colors */
  --black-100: #000;
  --white-100: #fff;
  --primary-color: #ad00ff;
  --secondary: #ffe119;
  --color-gainsboro: rgba(39, 38, 38, 0);

  /* Gaps */
  --gap-8xs-4: 0.28rem;
  --gap-8xs: 0.31rem;
  --gap-5xs: 0.5rem;
  --gap-4xs: 0.56rem;
  --gap-3xs: 0.63rem;
  --gap-xs: 0.75rem;
  --gap-base-9: 0.99rem;
  --gap-base: 1rem;
  --gap-lg: 1.13rem;
  --gap-3xl-8: 1.43rem;
  --gap-5xl: 1.5rem;
  --gap-7xl: 1.63rem;
  --gap-13xl: 2rem;
  --gap-21xl: 2.5rem;
  --gap-29xl: 3rem;
  --gap-37xl: 3.5rem;
  --gap-45xl: 4rem;
  --gap-61xl: 5rem;
  --gap-91xl: 6.88rem;
  --gap-109xl: 8rem;
  --gap-121xl: 8.75rem;
  --gap-161xl: 11.25rem;

  /* Paddings */
  --padding-5xs: 0.5rem;
  --padding-8xs: 0.31rem;
  --padding-11xl: 1.88rem;
  --padding-5xl: 1.5rem;
  --padding-base: 1rem;
  --padding-13xl: 2rem;
  --padding-61xl: 5rem;
  --padding-199xl: 13.63rem;
  --padding-109xl: 8rem;
  --padding-4xs: 0.56rem;
  --padding-6xs: 0.44rem;
  --padding-27xl: 2.88rem;
  --padding-xl: 1.25rem;

  /* Border radiuses */
  --br-51xl: 70px;
  --br-27xl-3: 46.3px;
  --br-71xl: 90px;
  --br-9xs: 4px;
  --br-13xl-6: 32.6px;
  --br-14xl: 33px;
  --br-31xl: 50px;
  --br-43xl-6: 62.6px;
  --br-9xs-5: 3.5px;
  --br-xs: 12px;
  --br-78xl: 97px;
  --br-98xl: 117px;
  --br-28xl: 47px;
  --br-6xs: 7px;
  --br-61xl: 80px;
  --br-131xl: 150px;
  --br-41xl: 60px;
  --br-3xs: 10px;
  --br-127xl: 146px;
}

#root {
  min-height: 100svh;

  &:has(.desktop) {
    max-height: 100lvh;
  }

  /* &:has(.mobile) {
    overflow: hidden auto;
  } */
}

.a-reset {
  text-decoration: none;
  color: inherit;
}

.pageloader {
  background-color: #005a9e !important;
}

#wewe-navbar-mobile {
  bottom: 0vh;
  right: 0px;
  position: fixed;
  width: 100vw;
}

/* PROPORTIONAL SCALED */
.d-fixed-yellow {
  bottom: 0vh;
  right: 0px;
  left: 0px;
  position: fixed;
  /* width: 100vw; */
  height: 100%;

  .d-proportionalpopup {
    top: 0px;
    right: 0px;
    left: 0px;
    position: fixed;
    height: 100vh;
    transform-origin: 0 0;
    width: 1280px;
  }
}

.m-fixed-yellow {
  bottom: 0vh;
  right: 0px;
  position: fixed;
  width: 100vw;
  height: 100%;

  .m-proportionalpopup {
    bottom: 0vh;
    right: 0vh;
    left: 0vh;
    width: 100vw;
    transform-origin: 0 0;
    top: 100vh;
    position: sticky;
  }
}

#fixed-mobil-navbar {
  bottom: 0vh;
  right: 0px;
  left: 0px;
  position: fixed;

  /* width: 100vw; */
  /* height: 100%; */
  /* background-color: blueviolet; */

  &.opening {
    #wewe-navbar-mobile {
      animation: fromBottom .2s ease-out forwards;
    }
  }

  &.closing {
    #wewe-navbar-mobile {
      animation: toBottom .2s ease-out forwards;
    }
  }

  &.hiddenNavbar {
    #wewe-navbar-mobile {
      visibility: hidden;
      transition: visibility 0.1s ease-out forwards;
    }
  }

  #proportionalnavbar {
    transform-origin: 0 0;

    /* width: 100vw;

    bottom: 0vh;
    right: 0vh;
    left: 0vh;
    top: 100vh;

    position: sticky;
    background-color: cadetblue; */
}
}

#layout {

  &.mobile {
    width: 360px;
    display: flex;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;

    &.opening {
      #wewe-navbar {
        animation: fromBottom .2s ease-out forwards;
      }
    }

    &.closing {
      #wewe-navbar {
        animation: toBottom .2s ease-out forwards;
      }
    }

    &.hiddenNavbar {
      #wewe-navbar {
        visibility: hidden;
        transition: visibility 0.1s ease-out forwards;
      }
    }

    #scrolling-content {
      /* grid-row: 1; */
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  &.desktop {
    width: 1280px;
    display: flex;
    flex-flow: column;

    .top-margin {
      margin-top: 0.75rem;
    }

    .m-0 {
      margin: 0;
    }

    #wewe-navbar {
      position: fixed;
      z-index: 888;
    }

    &.opening {
      #wewe-navbar {
        animation: fromTop .2s ease-in forwards;
      }
    }

    &.closing {
      #wewe-nav0bar {
        animation: toTop .2s ease-out forwards;
      }
    }

    &.hiddenNavbar {
      #wewe-navbar {
        visibility: hidden;
        transition: visibility 0.2s ease-out;
        animation: toTop .2s ease-out;
      }
    }

    #scrolling-content {
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 2.69rem;

      display: flex;
      flex-flow: column;
      height: 100%;
    }
  }

  position: relative;
  transform-origin: 0 0;
  background-color: var(--black-100);
  /* position: fixed; */

}

@import url(./css/component_navbar.css);
@import url(./css/component_menu.css);
@import url(./css/component_footer.css);
@import url(./css/component_contactPanel.css);
@import url(./css/component_sayhi.css);
@import url(./css/component_fullscreen.css);
@import url(./css/component_modal.css);

@import url(./css/page_services.css);
@import url('./css/component_services_card.css');
@import url('./css/page_services_detail_left.css');
@import url('./css/page_services_detail_right.css');
@import url(./css/page_services_detail_mobile.css);

@import url(./css/page_portfolio.css);
@import url(./css/page_portfolio_detail.css);

@import url(./css/page_landing.css);
@import url(./css/page_story.css);
@import url(./css/page_contact.css);
@import url(./css/page_404.css);