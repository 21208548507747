#desktop-contact {
  .mb-5 {
    margin-bottom: 5rem;
  }

  .design-category {
    position: relative;
    line-height: 5.94rem;
  }

  .secondary {
    position: relative;
    text-decoration: underline;
  }

  .primary-button {
    border-radius: var(--br-127xl);
    display: flex;
    flex-direction: row;
  }

  .design-category-parent,
  .primary-button,
  .primary-button-parent {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .primary-button-parent {
    display: flex;
    flex-direction: row;
    gap: 2.94rem;
    margin-top: -0.37rem;
    text-align: left;
    font-size: var(--font-size-5xl);
    font-family: var(--font-suisse-intl-mono);
  }

  .design-category-parent {
    position: absolute;
    top: 19rem;
    left: 2.69rem;
    display: none;
    flex-direction: column;
    text-align: center;
  }

  .you-ask-wewe {
    position: relative;
    line-height: 4.69rem;
    text-transform: uppercase;
  }

  .bewewewewestudiosk-5 {
    position: absolute;
    top: 0;
    left: 0;
    text-decoration: underline;
    font-weight: 700;

    text-decoration-skip-ink: none;
    text-underline-offset: 0.4rem;
  }

  .desktop-mail-black-2-2 {
    width: 46.56rem;
    position: relative;
    height: 5.69rem;
  }

  .you-ask-wewe-answers-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 0 0 var(--padding-13xl);
  }

  .take-a-look-3 {
    align-self: stretch;
    position: relative;
    line-height: 4.69rem;
    font-weight: 700;
  }

  .group-1004-3-2-icon {
    width: 6.25rem;
    position: relative;

    &:hover {
      /* https://cssfilterconverter.com/ */
      filter: brightness(0) saturate(100%) invert(13%) sepia(100%) saturate(6860%) hue-rotate(281deg) brightness(103%) contrast(116%);
    }
  }

  .frame-wrapper,
  .group-1004-3-2-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .group-1004-3-2-parent {
    gap: 0 12px;
  }

  .frame-wrapper {
    border-radius: var(--br-51xl);
  }

  .desktop-info-hovers,
  .take-a-look-3-parent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .take-a-look-3-parent {
    align-self: stretch;
    align-items: flex-start;
    padding: 0 0 0 var(--padding-13xl);
    gap: var(--gap-base);
  }

  .desktop-info-hovers {
    width: 80rem;
    background-color: var(--black-100);
    align-items: center;
    padding: var(--padding-13xl) 0;
    box-sizing: border-box;
    gap: var(--gap-45xl);
  }

  .company-details-2 {
    position: relative;
    line-height: 2.88rem;
    font-family: var(--font-suisse-intl-condensed-700);
  }

  .karpatsk-nmestie-10a {
    margin: 0;
  }

  .business-id-36768138-container {
    position: relative;
    font-weight: 600;
  }

  .wewe-studio-sro-2-parent {
    gap: var(--gap-21xl);
  }

  .frame-container,
  .virtulna-123-82-108-bratislav-wrapper,
  .wewe-studio-sro-2-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .frame-container {
    flex-direction: row;
    align-items: flex-end;
    gap: 11.75rem;
    font-size: var(--font-size-29xl);
  }

  .dokument-1-3 {
    position: relative;
    text-decoration: underline;
    font-weight: 600;

    &:hover {
      cursor: pointer;
      color: var(--primary-color);
    }
  }

  .frame-3023-2-parent {
    gap: var(--gap-13xl);
    font-size: var(--font-size-29xl);
  }

  .desktop-info-hovers-parent,
  .frame-3023-2-parent,
  .frame-group,
  .frame-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .frame-group {
    padding: 0 0 0 var(--padding-base);
    gap: var(--gap-29xl);
    font-size: 4rem;
    color: var(--black-100);
  }

  .desktop-info-hovers-parent,
  .frame-parent {
    gap: var(--gap-37xl);
  }
 
  .frame-parent {
    /* position: absolute; */
    /* top: 2.69rem; */
    left: 0;
    gap: var(--gap-61xl);
    font-size: var(--font-size-51xl);
    color: var(--white-100);
  }

  .wewe {
    position: relative;
    cursor: pointer;
  }

  .component-11-icon {
    width: 0.88rem;
    position: relative;
    height: 2.69rem;
  }

  .slovak-visual-design-container {
    position: relative;
  }

  .frame-2914-10,
  .frame-68-10 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .frame-2914-10 {
    padding: var(--padding-8xs) 0 0 var(--padding-5xs);
    font-size: var(--font-size-3xs);
    font-family: var(--font-suisse-intl-mono);
  }

  .frame-68-10 {
    width: 17.06rem;
  }

  .story {
    color: var(--black-100);
  }

  .button-5-2 {
    justify-content: center;
  }

  .button-5-2,
  .frame-2822-10,
  .header-mobilheader-desktop-10 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .frame-2822-10 {
    align-self: stretch;
    flex: 1;
    justify-content: flex-end;
    gap: var(--gap-5xl);
    font-size: var(--font-size-11xl);
    color: var(--white-100);
  }

  .header-mobilheader-desktop-10 {
    /* position: absolute; */
    top: 0;
    left: 0;
    background-color: var(--white-100);
    width: 80rem;
    overflow: hidden;
    justify-content: flex-start;
    padding: 0 var(--padding-5xs);
    box-sizing: border-box;
    gap: var(--gap-3xs);
    font-size: var(--font-size-14xl);
  }

  .desktop-contact {
    width: 100%;
    position: relative;
    background-color: var(--white-100);
    /* height: 133.06rem; */
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
    font-size: 2.31rem;
    color: var(--black-100);
    font-family: var(--font-suisse-intl-condensed);
  }
}

#mobile-contact {
  .mb-5 {
    margin-bottom: 5rem;
  }
  
  .you-ask {
    margin: 0;
  }

  .you-ask-wewe-container {
    position: relative;
    line-height: 3rem;
    text-transform: uppercase;
  }

  .designwewestudiosk-16-3 {
    position: absolute;
    top: 0%;
    left: 0%;
    text-decoration: underline;
  }

  .mobil-mail-black-2 {
    width: 19.88rem;
    position: relative;
    height: 2.63rem;
    font-size: var(--font-size-13xl);
  }

  .frame-2988-6 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-5xs);
  }

  .take-a-look-16 {
    width: 14.56rem;
    position: relative;
    line-height: 2.13rem;
    display: inline-block;
  }

  .group-1004-15-icon {
    width: 2.91rem;
    position: relative;
    height: 2.91rem;
  }

  .frame-3046-15 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }

  .frame-2982-15 {
    border-radius: 32.59px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .take-a-look-16-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-5xs);
    font-size: var(--font-size-13xl);
  }

  .mobil-service-info {
    width: 22.5rem;
    background-color: var(--black-100);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-45xl);
  }

  .company-details {
    position: relative;
    line-height: 1.44rem;
    font-family: var(--font-suisse-intl-condensed-700);
  }

  .wewe-studio-sro {
    position: relative;
    font-weight: 600;
  }

  .wewe-studio-sro-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    font-size: var(--font-size-5xl);
  }

  .company-details-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-21xl);
  }

  .dokument-1 {
    position: relative;
    text-decoration: underline;
    font-weight: 600;
  }

  .dokument-1-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.06rem;
    font-size: var(--font-size-5xl);
  }

  .frame-parent2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem 0rem 0rem var(--padding-5xs);
    gap: var(--gap-21xl);
  }

  .frame-wrapper1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 2rem;
    color: var(--black-100);
  }

  .services-8 {
    position: relative;
    text-decoration: underline;
    line-height: 5.63rem;
    text-transform: lowercase;
  }

  .portfolio-8 {
    position: relative;
    text-decoration: underline;
    line-height: 6.25rem;
    text-transform: lowercase;
  }

  .story-8 {
    width: 7.88rem;
    position: relative;
    text-decoration: underline;
    line-height: 5.63rem;
    text-transform: lowercase;
    display: inline-block;
  }

  .frame-2821-8 {
    align-self: stretch;
    width: 22.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xs) 0rem 0rem var(--padding-base);
    box-sizing: border-box;
  }

  .frame-2916-8 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .rectangle-1160-2-22 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    background-color: var(--black-100);
  }

  .wewe-22 {
    position: absolute;
    top: 15.09%;
    left: 1.67%;
  }

  .wewe-3-icon2 {
    position: absolute;
    height: 69.81%;
    width: 6.94%;
    top: 15.09%;
    right: 29.17%;
    bottom: 15.09%;
    left: 63.89%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }

  .mobil-footer-logo-animacia-2-22 {
    width: 22.5rem;
    position: relative;
    height: 6.63rem;
    font-size: var(--font-size-83xl-5);
  }

  .frame-2917-8 {
    position: absolute;
    top: 0rem;
    left: 0rem;
    background-color: var(--black-100);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }

  .mobil-landing-8 {
    width: 22.5rem;
    position: relative;
    height: 31.25rem;
    font-size: var(--font-size-41xl);
  }

  .mobil-service-info-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-29xl);
    /* padding-bottom: var(--gap-29xl); */
  }

  .mobile-contact {
    width: 100%;
    position: relative;
    background-color: var(--white-100);
    height: 100%;
    text-align: left;
    font-size: var(--font-size-29xl);
    color: var(--white-100);
    font-family: var(--font-suisse-intl-condensed);
  }

}