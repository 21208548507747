#desktop-popup-container-sayhi {
  z-index: 9998;

  .background {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* height: 100%; */
  }

  .click-capturer {
    display: inline-block;
    position: relative;
    height: 100%;
    z-index: 9998;
  }

  .background-fullscreen {
    display: grid;
    /* justify-content: end; */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    height: 100%;
  }

  .click-capturer-fullscreen {
    display: inline-block;
    position: relative;
    height: 100%;
    z-index: 9998;
  }
}

#mobile-popup-container-sayhi {
  z-index: 9998;

  .background {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100lvh;
  }

  .click-capturer {
    display: inline-block;
    position: relative;
    height: 100%;
    z-index: 9998;
  }

  .background-fullscreen {
    display: grid;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    height: 100%;
  }

  .click-capturer-fullscreen {
    display: inline-block;
    position: relative;
    height: 100%;
    z-index: 9998;
  }
}

#desktop-popup-container-fullscreen {
  z-index: 9998;

  .background {
    display: flex;
    justify-content: end;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    height: 100%;
  }

  .click-capturer {
    display: inline-block;
    position: relative;
    height: 100%;
    z-index: 9998;
  }

  .background-fullscreen {
    display: grid;
    /* justify-content: end; */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    height: 100%;

    z-index: 9999;
  }

  .click-capturer-fullscreen {
    display: inline-block;
    position: relative;
    height: 100%;
    z-index: 9998;
  }
}

#mobile-popup-container-menu {
  z-index: 9998;

  .background {
    /* display: flex;
    justify-content: end;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%; */
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /* height: 100lvh; */
  }

  .click-capturer {
    display: inline-block;
    position: relative;
    height: 100%;
    z-index: 9998;
  }

  .background-fullscreen {
    display: grid;
    /* justify-content: end; */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    height: 100%;
  }

  .click-capturer-fullscreen {
    display: inline-block;
    position: relative;
    height: 100%;
    z-index: 9998;
  }
}