/* STORY - DESKTOP  */
#desktop-story {
  .story-not-story-container {
    position: relative;
    line-height: 11.88rem;
  }

  .story-not-story-h12 {
    width: 78rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .story-not-story-h11 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .were-more-than-1 {
    margin: 0;
    white-space: pre-wrap;
  }

  .were-more-than-2 {
    margin: 0;
    white-space: pre-wrap;
  }

  .were-more-than {
    margin: 0;
    white-space: pre-wrap;
    font-weight: 700;
    font-size: 13.125rem;
  }

  .story-not-story-container1 {
    width: 77.88rem;
    position: relative;
    font-size: var(--font-size-91xl);
    line-height: 7.5rem;
    display: inline-block;
  }

  .story-not-story-h1-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-61xl);
  }

  .story-team-wewe {
    width: 21rem;
    position: relative;
    line-height: 3.69rem;
    display: inline-block;
    font-weight: 700;
    font-size: 4rem;
    margin: 0;
  }

  .image-4-4-icon {
    width: 24.44rem;
    position: relative;
    border-radius: var(--br-3xs);
    height: 32.63rem;
    object-fit: cover;
  }

  .matej1 {
    margin: 0;
    font-family: var(--font-suisse-intl-500);
  }

  .matej-founder-container1 {
    position: relative;
  }

  .frame-2833-4,
  .matej-photo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .frame-2833-4 {
    border-radius: var(--br-41xl);
    background-color: var(--white-100);
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-xl);
  }

  .matej-photo {
    border-radius: var(--br-131xl);
    flex-direction: column;
    gap: var(--gap-3xs);
  }

  .image-4-5-icon {
    width: 24rem;
    position: relative;
    border-radius: var(--br-3xs);
    height: 32.63rem;
    object-fit: cover;
  }

  .photos,
  .story-portraits {
    display: flex;
    justify-content: flex-start;
  }

  .photos {
    flex-direction: row;
    align-items: flex-start;
    gap: 1.25rem;
    text-align: center;
    font-size: var(--font-size-xl);
    font-family: var(--font-suisse-intl);
  }

  .story-portraits {
    flex-direction: column;
    align-items: center;
    gap: var(--gap-45xl);
    font-size: var(--font-size-46xl);
  }

  .frame-parent10 {
    gap: 10rem;
  }

  .footer-11-wrapper,
  .frame-parent10,
  .frame-parent9 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .frame-parent9 {
    /* position: absolute; */
    /* top: 2.69rem; */
    left: 0;
    gap: var(--gap-61xl);
    padding: 0 var(--padding-base);
  }

  .desktop-story {
    width: 100%;
    position: relative;
    background-color: var(--white-100);
    height: 225rem;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-191xl);
    color: var(--black-100);
    font-family: var(--font-suisse-intl-condensed-700);
  }
}

/* STORY - MOBILE */
#mobile-story {
  .not {
    margin: 0;
  }

  .story-not-story1 {
    position: relative;
    line-height: 3.625rem;
    font-family: var(--font-suisse-intl-condensed-700);
  }

  .story-not-story-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .story-not-story-h1 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .welcome-to-wewe-your-container {
    width: 21.5rem;
    position: relative;
    font-size: var(--font-size-16xl);
    line-height: 2.5rem;
    display: inline-block;
    font-family: var(--font-suisse-intl-condensed-700);
    white-space: pre-wrap;
  }

  .story-not-story {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: var(--padding-5xs) 0rem 0rem;
    gap: var(--gap-21xl);
  }

  .team-wewe {
    width: 21rem;
    position: relative;
    display: inline-block;
  }

  .team-wewe-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .frame-wrapper2 {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-size: var(--font-size-21xl);
  }

  .story-not-story-parent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-61xl);
  }

  .mobile-story-inner {
    /* position: absolute; */
    top: 0rem;
    left: 0rem;
    width: 22.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .image-4-icon {
    width: 19rem;
    position: relative;
    border-radius: var(--br-6xs);
    height: 25.38rem;
    object-fit: cover;
  }

  .matej {
    margin: 0;
    font-family: var(--font-suisse-intl-500);
  }

  .matej-founder-container {
    position: relative;
  }

  .matej-founder-pixelperfect-m-wrapper {
    border-radius: var(--br-28xl);
    background-color: var(--white-100);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-6xs) var(--padding-base);
  }

  .image-4-parent {
    border-radius: var(--br-98xl);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }

  .image-4-2-icon {
    width: 18.63rem;
    position: relative;
    border-radius: var(--br-6xs);
    height: 25.38rem;
    object-fit: cover;
  }

  .frame-parent5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-21xl);
  }

  .frame-parent4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-61xl);
    text-align: center;
    font-size: var(--font-size-xl);
    font-family: var(--font-suisse-intl);
    margin: var(--gap-61xl) 0 0 0;
    padding-bottom: var(--gap-61xl);
    width: 22.5rem;
    justify-content: space-around;
  }

  .mobile-story {
    width: 100%;
    position: relative;
    background-color: var(--white-100);
    height: 100%;
    text-align: left;
    font-size: var(--font-size-39xl);
    color: var(--black-100);
    font-family: var(--font-suisse-intl-condensed);
  }

  .story-team-wewe-mobile {
   font-family: var(--font-suisse-intl-condensed-700); 
   font-size: 2.5rem;
   margin:0;
  }
}