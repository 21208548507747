#desktop-404 {
  .animation-1-icon1 {
    position: absolute;
    top: -0.25rem;
    left: 1rem;
    /* width: 48.69rem; */
    height: 27rem;
    object-fit: cover;
  }

  .go-back-button-2-container {
    position: absolute;
    top: 0%;
    left: 0%;
    text-decoration: underline;
    cursor: pointer;
    text-decoration-skip-ink: none;
    text-underline-offset: 0.5rem;
    color: var(--white-100);
    text-wrap: nowrap;

    .span1{
      color: var(--white-100);
      text-decoration: underline;
      color: var(--white-100);
      font-size: 4.375rem;
      text-transform: uppercase;

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  .go-back-button {
    position: absolute;
    top: 1.25rem;
    left: 3.31rem;
    width: 18.06rem;
    height: 5.69rem;
  }

  .tell-us-how {
    margin: 0;
    white-space: pre-wrap;
    font-size: 4.375rem;
  }

  .claim {
    position: relative;
    line-height: 4.375rem;
    text-transform: uppercase;
  }

  .designwewestudiosk-21-2 {
    position: absolute;
    top: 0%;
    left: 0%;
    text-decoration: underline;

    color: var(--white-100);
    font-size: 4.375rem;

    &:hover {
      color: var(--primary-color);
    }
  }

  .designwewestudiosk-21 {
    width: 41.31rem;
    position: relative;
    height: 5.69rem;
  }

  .claim-parent {
    position: absolute;
    top: 27.75rem;
    left: 3.31rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .take-a-look-21-22 {
    position: absolute;
    top: 41.79rem;
    left: 2rem;
    font-size: var(--font-size-71xl);
    line-height: 3.8rem;
    /* display: none; */
  }

  .group-1003-19-2-icon2 {
    width: 5.58rem;
    position: relative;
    height: 5.58rem;
  }

  .frame-3046-19-22 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.42rem;
  }

  .frame-2982-20-22 {
    position: absolute;
    top: 48.32rem;
    left: 2rem;
    border-radius: 62.63px;
    /* display: none; */
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .go-back1 {
    position: absolute;
    top: 62.81rem;
    left: 23.5rem;
    font-size: var(--font-size-71xl);
    line-height: 5.93rem;
    display: inline-block;
    color: transparent;
    width: 26.88rem;
    height: 8.13rem;
  }

  .desktop-404 {
    width: 100%;
    position: relative;
    background-color: var(--black-100);
    height: 44.69rem;
    overflow-y: auto;
    text-align: left;
    font-size: var(--font-size-51xl);
    color: var(--white-100);
    font-family: var(--font-suisse-intl-condensed-700);
  }

}

#mobile-404 {
  .animation-1-icon {
    position: absolute;
    top: 5.69rem;
    left: 0.56rem;
    /* width: 21.38rem; */
    height: 24.13rem;
    object-fit: cover;
  }

  .span {
    line-height: false;
    text-transform: uppercase;
  }

  .ask-away-10 {
    position: absolute;
    top: 0.06rem;
    left: 1rem;
    font-size: 4.375rem;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-underline-offset: 0.5rem;
    color: var(--white-100);
    text-transform: uppercase;
  }

  .something-went-wrong {
    margin: 0;
  }

  .ask-away-101 {
    width: 18.56rem;
    position: relative;
    text-transform: uppercase;
    display: inline-block;
  }

  .designwewestudiosk-20 {
    position: relative;
    text-decoration: underline;
    color: var(--white-100);
  }

  .designwewestudiosk-20-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 30px;
  }

  .ask-away-10-parent {
    position: absolute;
    top: 32.81rem;
    left: 0.88rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    font-size: 34px;
  }

  .take-a-look-21-21 {
    position: absolute;
    top: 41.79rem;
    left: 2rem;
    line-height: 3.8rem;
    /* display: none; */
  }

  .group-1003-19-2-icon1 {
    width: 5.58rem;
    position: relative;
    height: 5.58rem;
  }

  .frame-3046-19-21 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.42rem;
  }

  .frame-2982-20-21 {
    position: absolute;
    top: 48.32rem;
    left: 2rem;
    border-radius: 62.63px;
    /* display: none; */
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .go-back {
    position: absolute;
    top: 62.81rem;
    left: 9.13rem;
    line-height: 5.93rem;
    display: inline-block;
    color: transparent;
    width: 26.88rem;
    height: 8.13rem;
  }

  .mobile-404 {
    width: 100%;
    position: relative;
    background-color: var(--black-100);
    height: 49.69rem;
    overflow-y: auto;
    text-align: left;
    font-size: var(--font-size-71xl);
    color: var(--white-100);
    font-family: var(--font-suisse-intl-condensed-700);
  }

}