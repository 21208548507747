#desktop-portfolio-detail {

  .gif-punctuation-1-icon2 {
    width: 100%;
    position: relative;
    /* height: 27.75rem; */
    object-fit: cover;
    mix-blend-mode: multiply;
  }

  .benditomockup-to-free-book-1-icon2,
  .icon4 {
    width: 49.38rem;
    position: relative;
    height: 66.81rem;
    object-fit: cover;
  }

  .icon4 {
    height: 27.75rem;
  }

  .benditomockup-free-newspaper-2-icon2,
  .logo-grid-15-22 {
    width: 49.38rem;
    position: relative;
    height: 27.81rem;
    object-fit: cover;
  }

  .benditomockup-free-newspaper-2-icon2 {
    height: 45.14rem;
  }

  .b7c69c134383587242079132438d50-icon2,
  .logo-grid-17-12 {
    width: 49.38rem;
    position: relative;
    height: 19.38rem;
    object-fit: cover;
  }

  .b7c69c134383587242079132438d50-icon2 {
    height: 36.25rem;
  }

  .frame-wrapper4,
  .gif-punctuation-1-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .gif-punctuation-1-container {
    overflow: hidden;
    flex-direction: column;
    width: 50rem;
  }

  .frame-wrapper4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 80rem;
    flex-direction: row;
  }

  .group-div {
    width: 80rem;
    position: relative;
    height: 306.46rem;
  }

  .frame-parent11,
  .group-container {
    background-color: var(--white-100);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .group-container {
    width: 80rem;
    flex-direction: row;
  }

  .frame-parent11 {
    position: absolute;
    /* top: 2.69rem; */
    left: -0.06rem;
    flex-direction: column;
    gap: var(--gap-61xl);
  }

  .fullscreen4 {
    position: fixed;
    top: 3.63rem;
    left: 1rem;
    width: 5.81rem;
    height: 1.56rem;
    cursor: pointer;
    mix-blend-mode: difference;
    color: var(--white-100);
    z-index: 9;

    /* &:hover {
          cursor: pointer;
          color: var(--primary-color);
        } */
  }

  .desktop-portfolio-wewe-logo-child {
    position: absolute;
    width: 29.63rem;
    height: 48.81rem;
  }

  .logo-web-design3 {
    position: relative;
    line-height: 1.25rem;

    span{
      margin: 0;
    }
  }

  .frame-3128-3 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .wewe8 {
    margin: 0;
    white-space: pre-wrap;
  }

  .wewe8-h1 {
    margin: 0;
    white-space: pre-wrap;
    font-weight: 700;
    font-size: 4.063rem;
  }

  .wewe-logo-custom-container3 {
    align-self: stretch;
    position: relative;
    line-height: 4rem;
  }

  .frame-3127-3,
  .frame-3162-3 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .frame-3127-3 {
    font-size: var(--font-size-46xl);
    font-family: var(--font-suisse-intl-condensed-700);
  }

  .frame-3162-3 {
    gap: var(--gap-base);
  }

  .initial-research-and-container3 {
    align-self: stretch;
    position: relative;
    line-height: 1.25rem;
    font-family: var(--font-suisse-intl);
  }

  .frame-3125-3 {
    left: 50.38rem;
    border-radius: var(--br-9xs);
    width: 28.63rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xs) var(--padding-base) 0;
    box-sizing: border-box;
    gap: var(--gap-13xl);
    font-size: var(--font-size-base);
    margin: var(--padding-5xs) var(--padding-base) 0;
    font-family: var(--font-suisse-intl-mono);
    

    &.isFixed {
      position: fixed;
      top: 3.69rem;
    }

    &.isBottom {
      position: absolute;
      bottom: 0;
      padding-bottom: 5rem;
    }
  }

  .wewe9 {
    position: relative;
    cursor: pointer;
  }

  .component-11-icon5 {
    width: 0.88rem;
    position: relative;
    height: 2.69rem;
  }

  .slovak-visual-design-container5 {
    position: relative;
  }

  .frame-2914-7,
  .frame-68-7 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .frame-2914-7 {
    padding: var(--padding-8xs) 0 0 var(--padding-5xs);
    font-size: var(--font-size-3xs);
    font-family: var(--font-suisse-intl-mono);
  }

  .frame-68-7 {
    width: 17.06rem;
  }

  .story8 {
    color: var(--black-100);
  }

  .button-5-233 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .button-5-237 {
    justify-content: center;
    cursor: pointer;
    color: var(--primary-color);
  }

  .button-5-237,
  .frame-2822-7,
  .header-mobilheader-desktop-7 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .frame-2822-7 {
    align-self: stretch;
    flex: 1;
    justify-content: flex-end;
    gap: var(--gap-5xl);
    font-size: var(--font-size-11xl);
    color: var(--white-100);
  }

  .header-mobilheader-desktop-7 {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--white-100);
    width: 80rem;
    overflow: hidden;
    justify-content: flex-start;
    padding: 0 var(--padding-5xs);
    box-sizing: border-box;
    gap: var(--gap-3xs);
    font-size: var(--font-size-14xl);
  }

  .desktop-portfolio-wewe-logo {
    width: 100%;
    /* position: relative; */
    background-color: var(--white-100);
    /* height: 100%; */
    /* overflow-y: auto; */
    text-align: left;
    font-size: var(--font-size-6xl);
    color: var(--black-100);
    font-family: var(--font-suisse-intl-condensed);
    padding-bottom: 5rem;
    display: flex;
  }


}

#mobile-portfolio-detail {
  .logo-web-design1 {
    position: relative;
    line-height: 1.25rem;
  }

  .logo-web-design-custom-typ-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .logo {
    margin: 0;
    white-space: pre-wrap;
  }

  .wewe-logo-custom-container {
    align-self: stretch;
    position: relative;
    line-height: 3.63rem;
    font-family: var(--font-suisse-intl-condensed-700);
  }

  .wewe-logo-custom-built-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--font-size-39xl);
    font-family: var(--font-suisse-intl-condensed);
  }

  .frame-parent1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }

  .initial-research-and-container1 {
    width: 21rem;
    position: relative;
    line-height: 1.25rem;
    font-family: var(--font-suisse-intl);
    display: inline-block;
  }

  .frame-div {
    border-radius: var(--br-9xs);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }

  .frame-3207-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xs) var(--padding-5xs) 0rem;
  }

  .gif-punctuation-1-icon {
    width: 22.5rem;
    position: relative;
    /* height: 12.64rem; */
    object-fit: cover;
    mix-blend-mode: multiply;
  }

  .benditomockup-to-free-book-1-icon {
    width: 22.5rem;
    position: relative;
    height: 30.44rem;
    object-fit: cover;
  }

  .logo-grid-16-2 {
    width: 22.5rem;
    position: relative;
    height: 12.68rem;
    object-fit: cover;
  }

  .gif-grid-1-icon {
    width: 22.5rem;
    position: relative;
    height: 12.64rem;
    object-fit: cover;
  }

  .benditomockup-free-newspaper-2-icon {
    width: 22.5rem;
    position: relative;
    height: 20.58rem;
    object-fit: cover;
  }

  .logo-grid-17-1 {
    width: 22.5rem;
    position: relative;
    height: 8.83rem;
    object-fit: cover;
  }

  .b7c69c134383587242079132438d50-icon {
    width: 22.5rem;
    position: relative;
    height: 16.56rem;
    object-fit: cover;
  }

  .gif-punctuation-1-parent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 5rem;
  }

  .frame-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .frame-3207-2-parent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-45xl);
  }

  .frame-3208-2 {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .frame-3060-3 {
    width: 22.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-base) 0rem 0rem;
    box-sizing: border-box;
  }

  .mobile-portfolio-wewe-logo {
    width: 100%;
    position: relative;
    background-color: var(--white-100);
    /* height: 214.06rem; */
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--black-100);
    font-family: var(--font-suisse-intl-mono);
    /* margin-bottom: var(--gap-45xl); */
  }

}