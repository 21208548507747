/* okey */
/* HEADER  */
#desktop-header {

  position: sticky;
  top: 0;
  z-index: 2;

  #mainmenu {
    display: contents;

    a {
      color: var(--black-100);
      text-decoration: none;
      font-weight: 700;
      align-self: flex-end;

      &:before,
      &:after {
        color: var(--white-100);
        content: "!";
      }

      &:hover {
        color: var(--primary-color);
      }

      &.active {
        color: var(--primary-color);

        &:before,
        &:after {
          content: "!";
          color: var(--primary-color);
        }
      }
    }
  }

  .wewe-icon {
    width: 87px;
    position: relative;
    /* height: 18.7px; */
  }

  .wewe-wrapper {
    height: 43px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: var(--padding-6xs) 0 0; */
    box-sizing: border-box;
  }

  .component-11-icon {
    width: 14px;
    position: relative;
    height: 43px;
  }

  .slovak-visual {
    margin: 0;
  }

  .slovak-visual-design-container {
    position: relative;
  }

  .frame-parent,
  .header-claim-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .header-claim-logo {
    padding: var(--padding-8xs) 0 0 var(--padding-5xs);
  }

  .frame-parent {
    /* width: 273px; */
    cursor: pointer;
  }

  .story {
    color: var(--black-100);
  }

  .button-5-2 {
    justify-content: center;

    &:hover {
      color: var(--primary-color);
    }
  }

  .portfolio {
    color: var(--black-100);

    &:hover {
      color: var(--primary-color);
    }
  }

  .button-5-2,
  .button-5-2-parent,
  .desktop-header-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .button-5-2-parent {
    align-self: stretch;
    flex: 1;
    justify-content: flex-end;
    gap: 0 24px;
    font-size: var(--font-size-11xl);
    color: var(--white-100);
    font-family: var(--font-suisse-intl-condensed-700);
  }

  .desktop-header-2 {
    width: 1280px;
    background-color: var(--white-100);
    overflow: hidden;
    justify-content: flex-start;
    padding: 0 var(--padding-5xs);
    box-sizing: border-box;
    gap: 0 10px;
    text-align: left;
    font-size: var(--font-size-3xs);
    color: var(--black-100);
    font-family: var(--font-suisse-intl-mono);
  }

}

/* HEADER - MOBILE */
#mobile-header {

  .wewe-26 {
    position: relative;
    /* align-self: flex-end; */
    break-after: avoid;
    white-space: nowrap;
  }

  .wewe-logo-animation-2-21 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .wewe-logo1 {
    width: 5.375rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .rectangle-1157-21 {
    width: 2.5rem;
    position: relative;
    background-color: var(--black-100);
    height: 0.313rem;
  }

  .menu-pictogram1 {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.625rem 0rem;
    cursor: pointer;
  }

  .sahi-hi-button1 {
    align-self: stretch;
    width: 5.375rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    font-size: var(--font-size-11xl);
  }

  .header-mobil-child {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
  }

  .header-mobil1 {
    align-self: stretch;
    flex: 1;
    background-color: var(--white-100);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0rem var(--padding-base);
  }

  .header-mobil-container {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .mobil-header {
    /* position: absolute; */
    /* top: 46.25rem; */
    /* left: 0rem; */
    background-color: var(--white-100);
    width: 22.5rem;
    height: 3.75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    font-size: var(--font-size-14xl);
    color: var(--black-100);
    font-family: var(--font-suisse-intl-condensed-700);
  }
  }