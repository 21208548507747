.with-our-design,
.you-will-slay {
  margin: 0;
}

.you-will-slay-container {
  width: 590px;
  position: relative;
  display: inline-block;
  line-height: var(--font-size-49xl);

  .you-will-slay {
    font-size: var(--font-size-49xl);
    font-family: var(--font-suisse-intl);
  }

  .you-will-slay-h2 {
    margin: 0;
    font-weight: 700;
    /* white-space: pre-wrap; */
    font-size: 4.3125rem !important;
  }
}

.frame-3068-9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 3.5rem;
}

.why-settle-for {
  margin: 0;
}

.why-settle-for-container {
  width: 590px;
  flex: 1;
  position: relative;
  display: inline-block;
}

.frame-3069-9,
.frame-3078-9 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-3069-9 {
  font-size: var(--font-size-xl);
  font-family: var(--font-suisse-intl);
}

.frame-3078-9 {
  gap: var(--gap-5xl);
}

.visual-design-4 {
  width: 590px;
  position: relative;
  font-size: var(--font-size-21xl);
  text-decoration: underline;
  font-family: var(--font-suisse-intl-mono);
  color: var(--primary-color);
  display: flex;
  align-items: center;
}

.visual-design-card {
  width: 616px;
  border-radius: var(--br-xs);
  background-color: var(--black-100);
  height: 444px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-base);
  cursor: pointer;
  text-align: left;
  font-size: var(--font-size-50xl);
  color: var(--white-100);
  font-family: var(--font-suisse-intl-condensed-700);
}