#mobile-services-detail {
  .visual-design {
    position: relative;
    line-height: 3.63rem;
  }

  .visual-design-h1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem var(--padding-base) 0rem 0rem;
    font-family: var(--font-suisse-intl-condensed-700);
  }


  .of-tailored-design {
    margin: 0;
    white-space: pre-wrap;
  }

  .wewe-can-help-container3 {
    width: 100%;
    white-space: pre-wrap;
  }

  .wewe-can-help-container2 {
    width: 22.38rem;
    position: relative;
    line-height: 1.38rem;
    display: flex;
    align-items: center;
  }

  .uxui15 {
    position: relative;
    text-decoration: underline;
    line-height: 1.75rem;
    text-decoration-skip-ink: none;
    text-underline-offset: 0.15rem;

    &>a {
      color: var(--white-100);
    }
  }

  .services-button {
    height: 1.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .services-button-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .services-button-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    flex-wrap: wrap;
    width: 70%;
  }

  .services-button-6-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
  }

  .frame-parent25 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }

  .frame-parent24 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    font-size: var(--font-size-lg);
  }

  .wewe-can-help-will-multiple-ty-parent22 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    font-size: var(--font-size-5xl);
    padding-bottom: 2rem;
  }

  .visual-design-h1-parent {
    width: 22.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xs) 0rem 0rem var(--padding-5xs);
    box-sizing: border-box;
    gap: var(--gap-161xl);
  }

  .web-design-built {
    width: 21.56rem;
    position: relative;
    line-height: 2.5rem;
    display: inline-block;
    flex-shrink: 0;
    /* padding-top: 2rem; */
    font-family: var(--font-suisse-intl-condensed-700);
  }

  .web-design-built-for-custom-ex-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    white-space: pre-wrap;
  }

  .frame-inner {
    width: 21.56rem;
    position: relative;
    height: 10.11rem;
  }

  .frame-parent288 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }

  .in-todays-digital3 {
    width: 21.13rem;
    position: relative;
    display: inline-block;
    flex-shrink: 0;
    white-space: pre-wrap;
    font-size: 14px;
  }

  .in-todays-digital-era-your-w-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem 0rem 0rem var(--padding-6xs);
    text-align: left;
    font-size: var(--font-size-sm);
    font-family: var(--font-suisse-intl);
  }

  .frame-parent277 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem var(--padding-5xs);
    gap: var(--gap-29xl);
    margin-top: var(--gap-13xl);
    margin-bottom: var(--gap-13xl);
  }

  .app-design-built {
    align-self: stretch;
    position: relative;
    line-height: 2.5rem;
  }

  .app-design-built-for-custom-ex-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .frame-child1 {
    width: 21.53rem;
    position: relative;
    height: 10.09rem;
    object-fit: contain;
  }

  .mobile-applications-have6 {
    flex: 1;
    position: relative;
    white-space: pre-wrap;
  }

  .mobile-applications-have-becom-container {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem var(--padding-6xs);
    text-align: left;
    font-size: var(--font-size-sm);
    font-family: var(--font-suisse-intl);
  }

  .frame-parent29 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem var(--padding-5xs);
    gap: var(--gap-13xl);
  }

  .frame-child2 {
    width: 21.54rem;
    position: relative;
    height: 10.1rem;
    object-fit: contain;
  }

  .you-ask-wewe5 {
    position: relative;
    line-height: 3rem;
    text-transform: uppercase;
    white-space: pre-wrap;
    font-size: 48px;
    font-weight: 700;
    /* white-space: nowrap; */
}

  .frame-wrapper8 {
    width: 21.56rem;
    height: 10.13rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .logo-design-built-for-custom-e-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }

  .rectangle-1176-4 {
    position: absolute;
    top: 0rem;
    left: 0rem;
    background-color: var(--color-gainsboro);
    width: 80rem;
    height: 45rem;
  }

  .vector-icon {
    position: absolute;
    top: 0.96rem;
    left: 4.63rem;
    width: 12.29rem;
    height: 8.17rem;
  }

  .rectangle-1176-4-parent {
    width: 21.56rem;
    position: relative;
    background-color: var(--black-100);
    height: 10.11rem;
  }

  .vector-icon1 {
    position: absolute;
    top: 0.98rem;
    left: 4.63rem;
    width: 12.3rem;
    height: 8.17rem;
  }

  .frame-wrapper10 {
    align-self: stretch;
    height: 10.13rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .infographics-are-a-container {
    align-self: stretch;
    flex: 1;
    position: relative;
  }

  .frame-parent26 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* gap: var(--gap-45xl); */
    text-align: center;
    font-size: var(--font-size-21xl);
  }

  .frame-parent23 {
    width: 22.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-45xl);
  }

  .frame-parent22 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

#desktop-services-detail { }

  .frame-3046-152 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0 12px;
  }

  .you-ask-wewe5 {
    position: relative;
    line-height: 3rem;
    text-transform: uppercase;
    font-weight: 700;
}

  .of-tailored-design {
    margin: 0;
  }

  .designwewestudiosk-31 {
    position: relative;
    font-size: 30px;
    text-decoration: underline;
    color: var(--black-100);

  }

  .group-1004-19-2-icon3 {
    width: 2.94rem;
    position: relative;
    height: 2.94rem;
  }

  .ask-away-3-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }

  .take-a-look-31 {
    width: 20.06rem;
    position: relative;
    line-height: 2rem;
    display: inline-block;
    font-size:34px;
  }

  .group-1004-3-icon {
    width: 2.94rem;
    position: relative;
    height: 2.94rem;
  }

  .frame-2982-3 {
    border-radius: var(--br-14xl);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .take-a-look-3-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    font-size: var(--font-size-13xl);
  }

  .frame-3149-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-45xl);
  }

  .frame-3147-3 {
    width: 22.5rem;
    background-color: var(--white-100);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xl) var(--padding-base) var(--padding-13xl);
    box-sizing: border-box;
    font-size: var(--font-size-29xl);
    color: var(--black-100);
    font-family: var(--font-suisse-intl-condensed-700);
  }

  .frame-2931-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .frame-parent21 {
    width: 22.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .mobile-services-visual-design-inner {
    /* position: absolute; */
    top: 0rem;
    left: 0rem;
    width: 22.5rem;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .mobile-services-visual-design {
    width: 100%;
    position: relative;
    background-color: var(--black-100);
    height: 100%;
    text-align: left;
    font-size: var(--font-size-39xl);
    color: var(--white-100);
    font-family: var(--font-suisse-intl-condensed);
  }

  .wewe-can-help3 {
    align-self: stretch;
    position: relative;
    line-height: 22px;
  }

  .uxui-25-2 {
    position: relative;
    text-decoration: underline;
    line-height: 28px;
    text-transform: uppercase;
    text-decoration-skip-ink: none;
    text-underline-offset: 0.15rem;

    &.inactive {
      text-decoration: none;

      &:hover {
        cursor: default;
        color: var(--white-100);
      }
    }
  }

  .services-button-25-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .uxui-26-2 {
    position: relative;
    line-height: 28px;
    text-transform: uppercase;
  }

  .services-button-25-2-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    font-size: var(--font-size-lg);
  }

  .property-1variant2-42 {
    width: 360px;
    background-color: var(--black-100);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-61xl) var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-base);
    text-align: left;
    font-size: var(--font-size-5xl);
    color: var(--white-100);
    font-family: var(--font-suisse-intl-condensed);
  }