#desktop-footer {
  .footer-replaceme {
    font-size: 21rem;
  }

  .our-story-5-22 {
    position: absolute;
    width: 99.21%;
    top: 0;
    left: 0;
    line-height: 6.56rem;
    display: inline-block;
  }

  .our-story-21 {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 6.56rem;
  }

  .footer-services-button1,
  .footer-contact-button1,
  .footer-portfolio-button1,
  .footer-story-button-2-21 {
    width: 23.94rem;
    position: relative;
    height: 6.56rem;
    cursor: pointer;

    &:hover {
      color: var(--primary-color);
    }
  }

  .footer-contact-button1 {
    width: 21.25rem;
  }

  .frame-2961-2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }

  /* .desktop-footer-button-2-21 {
        width: 14.38rem;
        position: relative;
        height: 6.56rem;
      } */
  .frame-18-2,
  .frame-2960-2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .frame-2960-2 {
    flex: 1;
    flex-direction: column;
    margin-left: -0.5rem;
  }

  .frame-18-2 {
    align-self: stretch;
    flex-direction: row;
    padding: var(--padding-base);
  }

  .logo-animations {
    width: 80rem;
    background-color: var(--black-100);
    height: 20.25rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 0rem 0rem 2.5rem var(--padding-5xl);
    box-sizing: border-box;
    cursor: pointer;
  }

  .footer-2,
  .frame-18-2-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
  }

  .frame-18-2-parent {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-7xl);
  }

  .footer-2 {
    background-color: var(--black-100);
    overflow: hidden;
    font-size: var(--font-size-91xl);
    color: var(--white-100);
    font-family: var(--font-suisse-intl-condensed-700);
    margin-bottom: -10px;
  }

  .footer-wewe-icon {
    width: 54rem;
  }

}

#mobile-footer {
  height: auto;

  .footer-replaceme {
    font-size: var(--font-size-83xl-5);
  }

  .services-11 {
    position: relative;
    text-decoration: underline;
    line-height: 5.63rem;
    text-transform: lowercase;
  }

  .portfolio-11 {
    position: relative;
    text-decoration: underline;
    line-height: 6.25rem;
    text-transform: lowercase;
  }

  .story-11 {
    width: 7.88rem;
    position: relative;
    text-decoration: underline;
    line-height: 5.63rem;
    text-transform: lowercase;
    display: inline-block;
    cursor: pointer;
  }

  .frame-2821-11 {
    align-self: stretch;
    width: 22.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xs) 0rem 0rem var(--padding-base);
    box-sizing: border-box;
    text-decoration-skip-ink: none;
    text-underline-offset: 0.4rem;
    font-family: var(--font-suisse-intl-condensed-700);
  }

  .frame-2916-11 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .rectangle-1160-2-2 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    background-color: var(--black-100);
  }

  .wewe-2 {
    /* position: relative; */
    top: 15.09%;
    left: 1.67%;
  }

  .wewe-3-icon {
    position: absolute;
    height: 69.81%;
    width: 6.94%;
    top: 15.09%;
    right: 29.17%;
    bottom: 15.09%;
    left: 63.89%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }

  .mobil-footer-logo-animacia-2-2 {
    width: 15.625rem;
    position: relative;
    height: 4.625rem;
    /* font-size: var(--font-size-83xl-5); */
    padding-left: 1rem;
    padding-bottom: 2.5rem;
    display: flex;
  }

  .frame-2917-11 {
    display: flex;
    flex-flow: column;
    gap: 2rem;
  }

  .mobil-landing-11 {
    width: 100%;
    position: relative;
    height: 100%;
    text-align: left;
    font-size: var(--font-size-41xl);
    color: var(--white-100);
    font-family: var(--font-suisse-intl-condensed);
  }

}