.brand1,
.identity1 {
  margin: 0;
}

.brand1 {
  margin: 0;
  font-size: 13.125rem;
  font-family: var(--font-suisse-intl-condensed-700);
}

.brand-identity-h1-container {
  align-self: stretch;
  position: relative;
  line-height: 11.25rem;
}

.uxui-3,
.wewe-can-help {
  position: relative;
  line-height: 3.13rem;
}

.wewe-can-help {
  align-self: stretch;
}

.uxui-3 {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-underline-offset: 0.4rem;

  &>a {
    color: var(--white-100);

    &:hover {
      color: var(--primary-color);
    }
  }
}

.ux {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-31 {
  position: relative;
  text-decoration: underline;
  line-height: 3.13rem;
}

.ux1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-32 {
  position: relative;
  text-decoration: underline;
  line-height: 3.13rem;
}

.ux2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-33 {
  position: relative;
  text-decoration: underline;
  line-height: 3.13rem;
}

.ux3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-34 {
  position: relative;
  text-decoration: underline;
  line-height: 3.13rem;
}

.ux4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-35 {
  position: relative;
  text-decoration: underline;
  line-height: 3.13rem;
}

.frame-3115-4,
.ux5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.frame-3115-4 {
  width: 77rem;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--font-size-13xl);
}

.brand-identity-anchors {
  font-size: var(--font-size-21xl);
}

.brand-identity-anchors,
.frame-3094-2,
.frame-3156-2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-3156-2 {
  gap: 5rem;
  /* margin-top: 2.69rem; */
}

.frame-3094-2 {
  padding: var(--padding-base) var(--padding-5xl) 0 var(--padding-base);
}

.and-brand-character,
.creation-of-identity {
  margin: 0;
}

.indetity-title {
  line-height: 4.06rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-wrap: balance;
}

.b7ca3b46682088ff2f07e45ab4c22-icon3 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-9xs);
  max-width: 100%;
  overflow: hidden;
  height: 37.5rem;
  flex-shrink: 0;
  object-fit: cover;
}

.indentity-foto {
  width: 80rem;
  height: 37.5rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
}

.in-todays-digital {
  align-self: stretch;
  flex: 1;
  position: relative;
}

.identity-body,
.indetity {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.identity-body {
  flex-direction: row;
  padding: 0 var(--padding-199xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  font-family: var(--font-suisse-intl);
}

.indetity {
  flex-direction: column;
  gap: var(--gap-13xl);
}

.brand-naming-title {
  align-self: center;
  position: relative;
  line-height: 4.06rem;
  font-size: 4.063rem;
  margin:0;
  font-family: var(--font-suisse-intl-condensed-700);
}

.b7ca3b46682088ff2f07e45ab4c22-icon4 {
  width: 100%;
  position: relative;
  border-radius: var(--br-9xs);
  /* height: 37.5rem; */
  object-fit: cover;
}

.brand-naming-photo {
  /* width: 78rem; */
  /* height: 37.5rem; */
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: var(--padding-base);
  padding-right: 20px;
  box-sizing: border-box;
}

.a-name-that,
.unlock-the-power {
  margin: 0;
}

.mobile-applications-have-container {
  align-self: stretch;
  flex: 1;
  position: relative;
}

.brand-naming,
.brand-naming-body {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.brand-naming-body {
  flex-direction: row;
  padding: 0 var(--padding-199xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  font-family: var(--font-suisse-intl);
}

.brand-naming {
  flex-direction: column;
  gap: var(--gap-13xl);
  padding-top: 4.5rem;
  padding-bottom: 3.5rem;
}

.ideamaking-title {
  align-self: stretch;
  position: relative;
  line-height: 4.06rem;
}

.b7ca3b46682088ff2f07e45ab4c22-icon5 {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.ideamaking-text {
  align-self: stretch;
  border-radius: var(--br-9xs);
  height: 37.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
}

.user-interface-ui1 {
  align-self: stretch;
  flex: 1;
  position: relative;
}

.ideamaking,
.ideamaking-body {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.ideamaking-body {
  flex-direction: row;
  padding: 0 var(--padding-199xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  font-family: var(--font-suisse-intl);
}

.ideamaking {
  flex-direction: column;
  gap: var(--gap-13xl);
}

.you-ask-wewe1 {
  position: relative;
  text-transform: uppercase;
}

.designwewestudiosk-16-4 {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: underline;
}

.mobil-mail-black-3 {
  width: 29.5rem;
  position: relative;
  height: 4.06rem;
}

.info-mail {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-base);
}

.take-a-look-16-2 {
  width: 14.56rem;
  position: relative;
  display: inline-block;
}

.group-1003-15-2-icon,
.group-1004-15-2-icon,
.group-16-2-icon {
  width: 4.13rem;
  position: relative;
  height: 4.13rem;
}

.frame-2982-15-2,
.frame-3046-15-2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-3046-15-2 {
  gap: 0.99rem;
}

.frame-2982-15-2 {
  border-radius: 46.26px;
}

.desktop-service-info,
.info-pictograms {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.info-pictograms {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-base);
}

.desktop-service-info {
  width: 80rem;
  background-color: var(--black-100);
  flex-direction: row;
  padding: var(--padding-base) var(--padding-109xl);
  box-sizing: border-box;
  gap: var(--gap-45xl);
  text-align: left;
  font-size: var(--font-size-31xl);
}

.visual-language-title {
  align-self: stretch;
  position: relative;
  line-height: 4.06rem;
}

.b7ca3b46682088ff2f07e45ab4c22-icon6 {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.visual-language-photo {
  width: 80rem;
  height: 37.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
}

.your-logo-is {
  align-self: stretch;
  flex: 1;
  position: relative;
}

.visual-language,
.visual-language-body {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.visual-language-body {
  flex-direction: row;
  padding: 0 var(--padding-199xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  font-family: var(--font-suisse-intl);
}

.visual-language {
  flex-direction: column;
  gap: var(--gap-13xl);
}

.visual-storytelling-title {
  align-self: stretch;
  position: relative;
  line-height: 4.06rem;
}

.b7ca3b46682088ff2f07e45ab4c22-icon7 {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.visual-storytelling-photo {
  width: 80rem;
  height: 37.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
}

.your-logo-is1 {
  align-self: stretch;
  flex: 1;
  position: relative;
}

.visual-storytelling,
.visual-storytelling-body {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.visual-storytelling-body {
  flex-direction: row;
  padding: 0 var(--padding-199xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  font-family: var(--font-suisse-intl);
}

.visual-storytelling {
  flex-direction: column;
  gap: var(--gap-13xl);
}

.design-manual-title {
  align-self: stretch;
  position: relative;
  line-height: 4.06rem;
}

.b7ca3b46682088ff2f07e45ab4c22-icon8 {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.design-manual-photo {
  width: 80rem;
  height: 37.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
}

.your-logo-is2 {
  flex: 1;
  position: relative;
}

.design-manual2,
.design-mual-body,
.frame-3091-6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.design-mual-body {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-199xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  font-family: var(--font-suisse-intl);
}

.design-manual2,
.frame-3091-6 {
  flex-direction: column;
}

.design-manual2 {
  align-self: stretch;
  gap: var(--gap-13xl);
}

.frame-3091-6 {
  width: 80rem;
  /* gap: var(--gap-61xl); */
}

.uxui,
.wewe-can-help1 {
  position: relative;
  line-height: 3.13rem;
}

.wewe-can-help1 {
  align-self: stretch;
  font-size: var(--font-size-21xl);
}

.uxui {
  text-decoration: underline;
}

.component-12 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui1 {
  position: relative;
  text-decoration: underline;
  line-height: 3.13rem;
}

.component-121 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui2 {
  position: relative;
  line-height: 3.13rem;
}

.component-122 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui3 {
  position: relative;
  text-decoration: underline;
  line-height: 3.13rem;
}

.component-12-parent,
.component-123 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.component-12-parent {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}

.uxui4 {
  position: relative;
  text-decoration: underline;
  line-height: 3.13rem;
}

.component-124 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.wewe-can-help-will-multiple-ty-parent {
  width: 80rem;
  background-color: var(--black-100);
  padding: var(--padding-61xl) var(--padding-13xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-13xl);
}

.frame-3069-2,
.frame-3091-6-parent,
.wewe-can-help-will-multiple-ty-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-3091-6-parent {
  text-align: left;
  font-size: var(--font-size-46xl);
}

.frame-3069-2 {
  width: 80rem;
  background-color: var(--black-100);
  /* gap: var(--gap-121xl); */
}

.not-sure-give {
  position: relative;
  line-height: 4.69rem;
  text-transform: uppercase;
}

.bewewewewestudiosk-6 {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: underline;
}

.desktop-mail-white-2-2 {
  width: 41.31rem;
  position: relative;
  height: 5.69rem;
  font-size: var(--font-size-51xl);
}

.frame-2988-2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 var(--padding-13xl);
}

.take-a-look-4 {
  align-self: stretch;
  position: relative;
  line-height: 4.69rem;
}

.group-1003-2-icon,
.group-1004-2-icon,
.group-4-icon {
  width: 6.25rem;
  position: relative;
  height: 6.25rem;
}

.frame-2982-2,
.frame-3046-2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-3046-2 {
  gap: var(--gap-5xl);
}

.frame-2982-2 {
  border-radius: var(--br-51xl);
}

.desktop-info-hovers1,
.frame-2989-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.frame-2989-2 {
  align-self: stretch;
  align-items: flex-start;
  padding: 0 0 0 var(--padding-13xl);
  gap: var(--gap-base);
  font-size: var(--font-size-51xl);
}

.desktop-info-hovers1 {
  width: 80rem;
  background-color: var(--white-100);
  align-items: center;
  padding: var(--padding-13xl) 0;
  box-sizing: border-box;
  gap: var(--gap-45xl);
}

.frame-3105-2,
.frame-3117-2,
.frame-3118-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-3117-2 {
  font-size: var(--font-size-56xl);
  color: var(--black-100);
}

.frame-3118-2 {
  /* position: absolute; */
  left: 0;
}

.wewe10 {
  position: relative;
  cursor: pointer;
}

.component-11-icon6 {
  width: 0.88rem;
  position: relative;
  height: 2.69rem;
}

.design-studio6,
.slovak-visual6 {
  margin: 0;
}

.slovak-visual-design-container6 {
  position: relative;
}

.frame-2914-3,
.frame-68-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.frame-2914-3 {
  padding: var(--padding-8xs) 0 0 var(--padding-5xs);
  font-size: var(--font-size-3xs);
  font-family: var(--font-suisse-intl-mono);
}

.frame-68-3 {
  width: 17.06rem;
}

.story9 {
  color: var(--black-100);
}

.button-5-242 {
  position: relative;
}

.button-5-241 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button-5-244 {
  position: relative;
}

.button-5-243 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--primary-color);
}

.portfolio2 {
  color: var(--black-100);
}

.button-5-246 {
  position: relative;
}

.button-5-245 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.say-hi6 {
  color: var(--black-100);
}

.button-5-248 {
  position: relative;
}

.button-5-247 {
  justify-content: center;
}

.button-5-247,
.frame-2822-3,
.header-mobilheader-desktop-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.frame-2822-3 {
  align-self: stretch;
  flex: 1;
  justify-content: flex-end;
  gap: var(--gap-5xl);
  font-size: var(--font-size-11xl);
  color: var(--white-100);
}

.header-mobilheader-desktop-3 {
  /* position: absolute; */
  height: 100%;
  margin-top: 0;
  left: 0;
  background-color: var(--white-100);
  width: 80rem;
  overflow: hidden;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  font-size: var(--font-size-14xl);
  color: var(--black-100);
}

.desktop-services-brand-identit {
  width: 100%;
  position: relative;
  background-color: var(--white-100);
  /* height: 510rem; */
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  font-size: var(--font-size-191xl);
  color: var(--white-100);
  font-family: var(--font-suisse-intl-condensed);
}

.wewe-can-help8 {
  align-self: stretch;
  position: relative;
  line-height: 22px;
}

.uxui-25-2 {
  position: relative;
  text-decoration: underline;
  line-height: 28px;
  text-transform: uppercase;

  &.inactive {
    text-decoration: none;

    &:hover {
      cursor: default;
      color: var(--white-100);
    }
  }
}

.services-button-25-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-26-2 {
  position: relative;
  line-height: 28px;
  text-transform: uppercase;
}

.services-button-26-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-27-2 {
  position: relative;
  text-decoration: underline;
  line-height: 28px;
  text-transform: uppercase;
}

.services-button-27-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-28-3 {
  position: relative;
  text-decoration: underline;
  line-height: 28px;
  text-transform: uppercase;
}

.services-button-28-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uxui-28-4 {
  position: relative;
  text-decoration: underline;
  line-height: 28px;
  text-transform: uppercase;
}

.services-button-28-4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.property-1variant2-42,
.services-button-25-2-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-lg);
}

.property-1variant2-42 {
  width: 360px;
  background-color: var(--black-100);
  padding: var(--padding-61xl) var(--padding-base);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--white-100);
  font-family: var(--font-suisse-intl-condensed);
}