/* SAY HI - DESKTOP */
#desktop-sayhi {
  height: 100%;
  z-index: 99;

  .formular-desktop-child {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: var(--secondary);
    width: 790px;
    height: 100%;
  }

  .wewere {
    margin: 0;
  }

  .wewere-all-ears {
    position: relative;
    line-height: 95px;
    text-transform: uppercase;
  }

  .wewere-all-ears-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .frame-container {
    width: 448px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .frame-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .bewewewewestudiosk-2-2 {
    position: absolute;
    top: 0%;
    left: 0%;
    text-decoration: underline;
    white-space: pre-wrap;
    color: var(--black-100);
    text-decoration-skip-ink: none;
    text-underline-offset: 0.4rem;
    
    &:hover {
      color: var(--primary-color);
    }
  }

  .designwewestudiosk-2-2 {
    width: 670px;
    position: relative;
    height: 92px;
    font-size: 70px;
  }

  .frame-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px 0px;
  }

  .group-child {
    position: absolute;
    top: 43.7px;
    left: 0px;
    background-color: var(--black-100);
    width: 61.7px;
    height: 8.1px;
    transform: rotate(-45.06deg);
    transform-origin: 0 0;
  }

  .rectangle-1160-6 {
    position: absolute;
    top: 0.5px;
    left: 6.4px;
    background-color: var(--black-100);
    width: 61.7px;
    height: 8.1px;
    transform: rotate(45.06deg);
    transform-origin: 0 0;
  }

  .rectangle-parent {
    width: 50px;
    position: relative;
    height: 50px;

    &:hover {

      .group-child,
      .rectangle-1160-6 {
        cursor: pointer;
        background-color: var(--primary-color);
      }
    }
  }

  .frame-parent {
    position: absolute;
    top: 24px;
    left: 17px;
    width: 749px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 999;
  }

  .group-995-2-2-icon {
    width: 6.25rem;
    position: relative;
 
    cursor: pointer;

    &:hover {
      /* https://cssfilterconverter.com/ */
      filter: brightness(0) saturate(100%) invert(13%) sepia(100%) saturate(6860%) hue-rotate(281deg) brightness(103%) contrast(116%);
    }
  }

  .group-995-2-2-parent {
    position: absolute;
    top: 508px;
    left: 16px;
    width: 322.4px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0px 12px;
  }

  .take-a-look {
    position: absolute;
    top: 416px;
    left: 10px;
    font-size: 70px;
    line-height: 70x;
    font-weight: 700;
  }

  .formular-desktop {
    width: 790px;
    height: 720px;
    overflow: hidden;
    text-align: left;
    font-size: 100px;
    color: var(--black-100);
    font-family: var(--font-suisse-intl-condensed-700);
  }
}

/* SAY HI - MOBILE */
#mobile-sayhi {
  width: 360px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: var(--font-suisse-intl-condensed-700);

  .wewere {
    margin: 0;
  }

  .wewere-all-ears-container {
    align-self: stretch;
    position: relative;
    line-height: 3.06rem;
    text-transform: uppercase;
  }

  .bewewewewestudiosk-4 {
    position: absolute;
    height: 33.62%;
    width: 97.24%;
    top: 0%;
    left: 0%;
    text-decoration: underline;
    display: inline-block;
    color: var(--black-100);
  }

  .designwewestudiosk-3 {
    align-self: stretch;
    flex: 1;
    position: relative;
    font-size: 30px;
  }

  .wewere-all-ears-parent {
    align-self: stretch;
    height: 14.56rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }

  .take-a-look-2 {
    position: relative;
    font-size: 34px;
  }

  .frame-child {
    width: 3.13rem;
    position: relative;
    height: 3.13rem;
  }

  .frame-container {
    width: 13.44rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 0 12px;
  }

  .group-child {
    position: absolute;
    top: 2.73rem;
    left: 0rem;
    background-color: var(--black-100);
    width: 3.86rem;
    height: 0.51rem;
    transform: rotate(-45.06deg);
    transform-origin: 0 0;
  }

  .group-item {
    position: absolute;
    top: 0.03rem;
    left: 0.4rem;
    background-color: var(--black-100);
    width: 3.86rem;
    height: 0.51rem;
    transform: rotate(45.06deg);
    transform-origin: 0 0;
  }

  .rectangle-parent {
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 3.13rem;
    height: 3.13rem;
  }

  .frame-group {
    display: flex;
    flex-direction: row;
    /* align-items: flex-end; */
    justify-content: flex-start;
    gap: 3.81rem;
  }

  .frame-parent {
    height: 24.81rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xl) 0rem;
    box-sizing: border-box;
    gap: var(--gap-5xs);
  }

  .mobil-formulr {
    position: relative;
    background-color: var(--secondary);
    /* height: 24.81rem; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem 1.06rem;
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    text-align: left;
    font-size: 3rem;
    /* font-size: 2.94rem; */
    color: var(--black-100);
    font-family: var(--font-suisse-intl-condensed-700);
  }
}